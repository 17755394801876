import styled from 'styled-components';
import { Color } from '../../types/styling.types';
import { ButtonProps } from './Button.types';

const ButtonStyled = styled.button<{ $color?: Color, $active?: boolean; disabled?: boolean }>`
border: none;
outline: none;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
flex-shrink: 0;
font-size: 13px;
padding: 0 14px;
border-radius: 3px;
text-transform: none;
height: 26px;
gap: 8px;
color: ${(props) => props.disabled ? props.theme.colors.greyMedium : props.theme.colors.greyLight};
background: ${(props) => props.$color ? props.theme.colors[props.$color] : ' transparent'};

${(props) => props.$active && `box-shadow: ${props.theme.container.boxShadow};`}

&:hover {
    box-shadow: ${(props) => props.theme.container.boxShadow};
}
`

const Button = ({
    disabled,
    loading,
    color,
    children,
    active,
    ...props
}: ButtonProps) => (
    <ButtonStyled disabled={disabled || loading} $color={color} $active={active} {...props}>
        {!!loading && <></>}
        {children}
    </ButtonStyled >
);

export default Button;