import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import withTransition from "../../hooks/withTransition/withTransition";
import { WithTransitionComponentProps, WithTransitionStatus } from "../../hooks/withTransition/withTransition.types";
import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon } from "../../icons";
import Icon from "../../icons/Icon/Icon";
import { translateText } from "../../utils/format";
import { ToastProps } from "./Toast.types";
import Card from "../Card/Card";
import { Color } from "../../types/styling.types";

const ToastDiv = styled(Card) <{ $transitionStatus: WithTransitionStatus }>`
position: relative;
transition: all 0.3s ease-in;
opacity: ${(props) => props.$transitionStatus !== 'idle' ? 0 : 1};
transform: ${(props) => props.$transitionStatus !== 'idle' ? 'translate(+100%)' : 'none'};
max-height: 140px;
padding: 8px 16px;
gap: 16px;
display: flex;
align-items: center;

& > .toast-close-icon {
    position: absolute;
    top: 6px;
    right: 6px;
}

& > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    & > div {
        white-space: pre-line;

        &:first-letter {
            text-transform: capitalize;
        }
    }
}
`

const ProgressDiv = styled.div<{ $color: Color, $transitionStatus: WithTransitionStatus }>`
content: "";
position: absolute;
bottom: 0;
left: 0;
height: 2px;
width: ${(props) => props.$transitionStatus === 'enter' ? '100%' : 0};
display: flex;
flex-shrink: 0;
flex-grow: 1;
transition: width 5s linear;
background-color: ${(props) => props.theme.colors[props.$color]};
`

const Toast = ({
    toast,
    onClick,
    transitionStatus,
    setTransitionStatus
}: ToastProps & WithTransitionComponentProps) => {
    const timeoutAuto = useRef<NodeJS.Timeout | null>(null);
    const [autoDelete, setAutoDelete] = useState<boolean>(true);
    const { t } = useTranslation();

    const clearAutoDelete = useCallback(() => {
        setAutoDelete(false);
        if (timeoutAuto.current) {
            clearTimeout(timeoutAuto.current);
        }
    }, []);

    useEffect(() => {
        timeoutAuto.current = setTimeout(() => setTransitionStatus('exit'), 5000);

        return () => {
            if (timeoutAuto.current) {
                clearTimeout(timeoutAuto.current);
            }
        }
    }, []);

    return (
        <ToastDiv $transitionStatus={transitionStatus} onMouseEnter={clearAutoDelete}>
            <Icon
                className="toast-close-icon"
                icon={CloseIcon}
                color="greyMedium"
                size="xs"
                onClick={() => setTransitionStatus('exit')}
                pointer
            />
            {toast.type === 'error' && <Icon icon={ErrorIcon} color="error" size="medium" />}
            {toast.type === 'success' && <Icon icon={SuccessIcon} color="success" size="medium" />}
            {toast.type === 'warning' && <Icon icon={WarningIcon} color="warning" size="medium" />}
            <div>
                <div>{translateText(toast.message, t)}</div>
                {toast.type === 'error' && <span className="link" onClick={onClick}>{t('messages:errors.contact_admin')}</span>}
            </div>
            {!!autoDelete && <ProgressDiv $color={toast.type} $transitionStatus={transitionStatus} />}
        </ToastDiv >
    );
}

export default withTransition(Toast, { enter: 300, exit: 300 });