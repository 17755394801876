import {
    SgBounds,
    SgCircle,
    SgPoint,
    SgRectangle,
    SgSegment,
} from "../sg-canvas/geometry/SgGeometry.interfaces";
import SgGeometryCircle from "../sg-canvas/geometry/SgGeometryCircle.class";
import SgGeometryPoint from "../sg-canvas/geometry/SgGeometryPoint.class";
import SgGeometryRectangle from "../sg-canvas/geometry/SgGeometryRectangle.class";
import SgGeometrySegment from "../sg-canvas/geometry/SgGeometrySegment.class";
import { isCircle } from "../sg-canvas/utils/helpers";
import SgSchematics from "../sg-schematics/SgSchematics.class";
import { SgSchematicsElement } from "../sg-schematics/SgSchematics.types";
import { SgFactoryElement, SgFactoryOptions } from "./SgFactory.types";

class SgFactory extends SgSchematics {
    // Elements
    private zones: SgSchematicsElement<SgGeometryRectangle>[] = [];
    private walls: SgSchematicsElement<SgGeometrySegment>[] = [];
    private equipments: SgSchematicsElement<
        SgGeometryRectangle | SgGeometryCircle
    >[] = [];

    constructor(
        canvasRef: HTMLCanvasElement,
        options?: Partial<SgFactoryOptions>
    ) {
        super(canvasRef, options);

        this.setZones(options?.zones);
        this.setWalls(options?.walls);
        this.setEquipments(options?.equipments);
        this.adaptToElements();
    }

    // Elements
    public getElements(): SgSchematicsElement[] {
        return [...(this.equipments ?? []), ...(this.walls ?? [])];
    }
    public addElement(element: SgSchematicsElement): void {
        if (element.shape instanceof SgGeometrySegment) {
            if (!this.walls) this.walls = [];
            this.walls.push(element as SgSchematicsElement<SgGeometrySegment>);
        } else {
            this.equipments.push(
                element as SgSchematicsElement<
                    SgGeometryRectangle | SgGeometryCircle
                >
            );
        }
        this.update();
    }

    public setZones(
        zones?:
            | SgSchematicsElement<SgGeometryRectangle>[]
            | SgFactoryElement<SgRectangle>[]
    ) {
        this.zones =
            zones?.map((z) => ({
                ...z,
                shape:
                    z.shape instanceof SgGeometryRectangle
                        ? z.shape
                        : new SgGeometryRectangle(z.shape),
            })) ?? [];
    }

    public setWalls(
        walls?:
            | SgSchematicsElement<SgGeometrySegment>[]
            | SgFactoryElement<SgSegment>[]
    ) {
        this.walls =
            walls?.map((w) => ({
                ...w,
                shape:
                    w.shape instanceof SgGeometrySegment
                        ? w.shape
                        : new SgGeometrySegment(w.shape),
            })) ?? [];
    }

    public setEquipments(
        equipments?:
            | SgSchematicsElement<SgGeometryRectangle | SgGeometryCircle>[]
            | SgFactoryElement<SgRectangle | SgCircle>[]
    ) {
        this.equipments =
            equipments?.map((e) => ({
                ...e,
                shape:
                    e.shape instanceof SgGeometryRectangle ||
                    e.shape instanceof SgGeometryCircle
                        ? e.shape
                        : isCircle(e.shape)
                        ? new SgGeometryCircle(e.shape as SgCircle)
                        : new SgGeometryRectangle(e.shape as SgRectangle),
            })) ?? [];
    }

    // Actions
    protected updateElementOver(coordinates: SgPoint) {
        super.updateElementOver(coordinates);

        if (!this.elementOver || this.mode !== "view") return;

        if (
            this.walls?.some((w) => w._id === this.elementOver?._id) ||
            this.zones?.some((w) => w._id === this.elementOver?._id)
        ) {
            this.elementOver = undefined;
        }
    }

    //Init
    private adaptToElements() {
        if (!this.zones?.length) return;
        const bounds: SgBounds = SgGeometryRectangle.bounds(
            this.getVisibleArea()
        );

        this.zones?.forEach((zone) => {
            const zBounds = zone.shape.getBounds();
            if (zBounds.xMin < bounds.xMin) bounds.xMin = zBounds.xMin;
            if (zBounds.xMax > bounds.xMax) bounds.xMax = zBounds.xMax;
            if (zBounds.yMin < bounds.yMin) bounds.yMin = zBounds.yMin;
            if (zBounds.yMax > bounds.yMax) bounds.yMax = zBounds.yMax;
        });

        const width = bounds.xMax - bounds.xMin;
        const height = bounds.yMax - bounds.yMin;

        const zoom = Math.max(
            (width * 1.1 * this.getZoom()) / this.getVisibleArea().width,
            (height * 1.1 * this.getZoom()) / this.getVisibleArea().height
        );

        if (!zoom) return;
        this.setZoom(1 / zoom);
        const zonesCentroid = SgGeometryRectangle.centroid({
            x: bounds.xMin,
            y: bounds.yMin,
            width,
            height,
        });
        const visibleAreaCentroid = SgGeometryRectangle.centroid(
            this.getVisibleArea()
        );
        this.setOffset(
            SgGeometryPoint.vector(zonesCentroid, visibleAreaCentroid)
        );
    }

    // Render
    protected renderElements() {
        const scaleRatio = this.getScaleRatio();
        this.setStyle({
            lineWidth: 2 / scaleRatio,
            lineDash: [],
            lineCap: "square",
            strokeStyle: this.options?.style?.line ?? "dimgrey",
            fillStyle: "rgba(255, 255, 255, 0.5",
        });
        this.zones?.forEach((w) => w.shape.draw(this.ctx, { fill: true }));
        this.walls?.forEach((w) => w.shape.draw(this.ctx));

        this.equipments?.forEach((equipment) => {
            const color =
                equipment.data?.status === 0
                    ? "189, 42, 42"
                    : equipment.data?.status === 1
                    ? "215, 133, 61"
                    : "44, 174, 63";

            this.setStyle({
                lineWidth: 2,
                lineDash: [],
                lineCap: "square",
                strokeStyle:
                    this.mode === "view"
                        ? `rgb(${color})`
                        : this.options?.style?.element ??
                          this.options?.style?.line ??
                          "dimgrey",
                fillStyle:
                    this.mode === "view" ? `rgba(${color}, 0.5)` : "white",
            });
            equipment.shape.draw(this.ctx, { fill: true, stroke: true });
        });
    }
}

export default SgFactory;
