import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import SgFactory from "../../sg-factory/SgFactory.class";
import { SgSchematicsElementSelectedData } from "../../sg-schematics/SgSchematics.types";
import { Page } from "../../sg-ui/components";
import { useUiContext } from "../../sg-ui/contexts";
import { PLAN, getBaths, getOptions } from "../../utils/mocks";
import FactoryFloorPopup from "./components/FactoryFloorPopup";

const PageDiv = styled(Page)`
position: relative;
padding: 0;
`

interface FactoryFloorProps {

}

const FactoryFloor = ({ }: FactoryFloorProps) => {
    const theme = useTheme();
    const { isDarkMode } = useUiContext();
    const canvasDomRef = useRef<HTMLCanvasElement>(null);
    const factoryRef = useRef<SgFactory | null>(null);
    const [selectedElement, setSelectedElement] = useState<SgSchematicsElementSelectedData | undefined>();

    const equipments = useMemo(() => getBaths(), []);

    useEffect(() => {
        if (canvasDomRef.current) {
            factoryRef.current = new SgFactory(
                canvasDomRef.current,
                {
                    ...getOptions(theme, isDarkMode),
                    mode: 'view',
                    walls: PLAN?.walls,
                    equipments: equipments,
                    zones: PLAN?.zones
                },
            );
            factoryRef.current.onSelectElement = setSelectedElement;
        }

        return () => {
            factoryRef.current?.destroy()
        }
    }, []);

    return (
        <PageDiv>
            <canvas
                className="floor-plan-canvas"
                ref={canvasDomRef}
                style={{ width: '100%', height: '100%' }}
            />
            <FactoryFloorPopup data={selectedElement} />
        </PageDiv>
    )
}
export default FactoryFloor;