import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import styled from 'styled-components';
import { useNotificationContext } from '../../contexts';

const ErrorPageDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 6px;
margin: auto;
`


const ErrorPage = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const { notify } = useNotificationContext();

    useEffect(() => {
        notify({ type: 'error', message: 'tes' })
    }, []);

    return (
        <ErrorPageDiv>
            <h1>{t('messages:errors.page_not_found')}</h1>
            <p>{t('messages:errors.page_not_found_description')}</p>
        </ErrorPageDiv>
    );
}
export default ErrorPage;