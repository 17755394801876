import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import FactoryFloorEdit from "./pages/FactoryFloorEdit/FactoryFloorEdit";
import FactoryFloor from "./pages/FactoryFloor/FactoryFloor";
import Main from "./pages/Main";
import ErrorPage from "./sg-ui/components/ErrorPage/ErrorPage";
import { UiProvider } from "./sg-ui/contexts";
import { NotificationProvider } from "./sg-ui/contexts/NotificationProvider/NotificationProvider";
import { theme } from "./styles/theme";

const App = () =>
    <UiProvider theme={theme}>
        <NotificationProvider>
            <RouterProvider router={createBrowserRouter([
                {
                    path: "/",
                    element: (<Main><Outlet /></Main>),
                    children: [
                        {
                            path: '/*',
                            element: <ErrorPage />,
                        },
                        {
                            path: '/',
                            element: <FactoryFloor />,
                        },
                        {
                            path: '/factory',
                            element: <FactoryFloor />,
                        },
                        {
                            path: '/editor',
                            element: <FactoryFloorEdit />,
                        },
                    ]
                },
            ])}
            />
        </NotificationProvider>
    </UiProvider>;

export default App;