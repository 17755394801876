import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ButtonIcon } from "../../components";
import { LeftNavProps } from "./LeftNav.types";
import { Icon } from "../../icons";
import { translateText } from "../../utils/format";
import { useTranslation } from "react-i18next";

const LeftNavDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
flex-shrink: 0;
gap: 12px;
width: 56px;
padding: 12px 0;
background-color: ${(props) => props.theme.container.backgroundColor};
background-image: ${(props) => props.theme.container.backgroundImage};
box-shadow: ${(props) => props.theme.container.boxShadow};
z-index: 2;

& > img {
    width: 80%;
    object-fit: contain;
    margin-bottom: 24px;
}
`

const LeftNavButton = styled(ButtonIcon) <{ active?: boolean }>`
height: fit-content;
padding: 10px 10px;

${(props) => props.active && `& > svg { fill: ${props.theme.colors.accent};}`}
`

const LeftNav = ({ logo, items }: LeftNavProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <LeftNavDiv>
            {!!logo && <img src={logo} alt="" />}
            {items.map(i => (
                <LeftNavButton
                    key={i.to}
                    title={translateText(i.title, t)}
                    onClick={() => navigate(i.to)}
                    icon={<Icon icon={i.icon} color={location.pathname.startsWith(i.to) ? 'accent' : undefined} size="medium" />}
                />
            ))}
        </LeftNavDiv>
    )
}
export default LeftNav;