import { ReactElement } from "react";
import { EditIcon, FactoryIcon } from "../../sg-ui/icons";
import { LayoutLeftNav, LeftNav } from "../../sg-ui/layouts";
import { LeftNavItem } from "../../sg-ui/layouts/LeftNav/LeftNav.types";

const ITEMS: LeftNavItem[] = [
    { title: 'Factory', to: '/factory', icon: FactoryIcon },
    { title: 'Editor', to: '/editor', icon: EditIcon },
];

const Main = ({ children }: { children: ReactElement }) => {


    return (
        <LayoutLeftNav>
            <LeftNav logo="/images/logo/logo_official_1.png" items={ITEMS} />
            {children}
        </LayoutLeftNav>
    );
}

export default Main;