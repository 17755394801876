import SgGeometry from "./SgGeometry.class";
import {
    SgCircle,
    SgGeometryCanvasStyle,
    SgPoint,
    SgVector,
} from "./SgGeometry.interfaces";
import SgGeometryPoint from "./SgGeometryPoint.class";

class SgGeometryCircle extends SgGeometry<SgCircle> {
    public clone(): SgGeometryCircle {
        return new SgGeometryCircle(this.geometry);
    }

    public getRadius(): number {
        return this.geometry.radius;
    }

    public scale(coefficient: number): SgGeometryCircle {
        this.setGeometry(SgGeometryCircle.scale(this, coefficient));
        this.update();
        return this;
    }

    public reverseScale(coefficient: number): SgGeometryCircle {
        return this.scale(coefficient === 0 ? 1 : 1 / coefficient);
    }

    public translate(vector: SgVector): SgGeometryCircle {
        this.setGeometry(SgGeometryCircle.translate(this, vector));
        this.update();
        return this;
    }

    public move(to: SgGeometryPoint | SgPoint): SgGeometryCircle {
        this.setGeometry(SgGeometryCircle.move(this, to));
        this.update();
        return this;
    }

    public equals(segment: SgGeometryCircle | SgCircle): boolean {
        return SgGeometryCircle.equals(this, segment);
    }

    public isInside(point: SgPoint | SgGeometryPoint): boolean {
        return SgGeometryCircle.isInside(this, point);
    }

    public isAround(
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return SgGeometryCircle.isAround(this, point, delta);
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return SgGeometryCircle.distance(this, point);
    }

    protected update() {
        this.origin = new SgGeometryPoint(this.geometry);
        this.centroid = this.origin;
        this.edges = [];
        this.vertices = [this.origin];
        this.bounds = {
            xMin: this.geometry.x - this.geometry.radius,
            xMax: this.geometry.x + this.geometry.radius,
            yMin: this.geometry.y - this.geometry.radius,
            yMax: this.geometry.y + this.geometry.radius,
        };
        this.boundingBox = {
            x: this.geometry.x - this.geometry.radius,
            y: this.geometry.y - this.geometry.radius,
            width: this.geometry.radius * 2,
            height: this.geometry.radius * 2,
        };
    }

    public draw(
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        SgGeometryCircle.draw(this, ctx, style);
    }

    static centroid(circle: SgGeometryCircle | SgCircle): SgPoint {
        const s = this.getGeometry(circle);
        return { x: s.x, y: s.y };
    }

    static distance(
        circle: SgGeometryCircle | SgCircle,
        point: SgPoint | SgGeometryPoint
    ): number {
        const c = this.getGeometry(circle);
        const distance = SgGeometryPoint.distance(c, point);

        return distance <= c.radius ? 0 : distance - c.radius;
    }

    static isAround(
        circle: SgGeometryCircle | SgCircle,
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return SgGeometryCircle.distance(circle, point) <= delta;
    }

    static isInside(
        circle: SgGeometryCircle | SgCircle,
        point: SgPoint | SgGeometryPoint
    ): boolean {
        return SgGeometryCircle.isAround(circle, point, 0);
    }

    static scale(
        circle: SgGeometryCircle | SgCircle,
        coefficient: number
    ): SgCircle {
        const c = this.getGeometry(circle);
        return {
            ...SgGeometryPoint.scale(c, coefficient),
            radius: c.radius * coefficient,
        };
    }

    static reverseScale(
        circle: SgGeometryCircle | SgCircle,
        coefficient: number
    ): SgCircle {
        return this.scale(circle, coefficient === 0 ? 1 : 1 / coefficient);
    }

    static translate(
        circle: SgGeometryCircle | SgCircle,
        vector: SgVector
    ): SgCircle {
        const c = this.getGeometry(circle);
        return {
            ...SgGeometryPoint.translate(c, vector),
            radius: c.radius,
        };
    }

    static move(
        circle: SgGeometryCircle | SgCircle,
        point: SgGeometryPoint | SgPoint
    ): SgCircle {
        const c = this.getGeometry(circle);
        return this.translate(c, SgGeometryPoint.vector(c, point));
    }

    static draw(
        circle: SgGeometryCircle | SgCircle,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        const c = this.getGeometry(circle);
        ctx.beginPath();
        ctx.arc(c.x, c.y, Math.floor(c.radius), 0, 2 * Math.PI);
        if (style?.stroke) ctx.stroke();
        if (style?.fill) ctx.fill();
        ctx.closePath();
    }

    static equals(
        circle1: SgGeometryCircle | SgCircle,
        circle2: SgGeometryCircle | SgCircle
    ): boolean {
        const c1 = this.getGeometry(circle1);
        const c2 = this.getGeometry(circle2);
        return SgGeometryPoint.equals(c1, c2) && c1.radius === c2.radius;
    }

    static getGeometry(circle: SgGeometryCircle | SgCircle): SgCircle {
        return circle instanceof SgGeometry ? circle.getGeometry() : circle;
    }

    static mesure(circle: SgGeometryCircle | SgCircle): number {
        const c = this.getGeometry(circle);
        return c.radius * c.radius * Math.PI;
    }
}

export default SgGeometryCircle;
