import { useMemo } from "react";
import styled from "styled-components";
import { Card } from "../../../sg-ui/components";
import { FactoryFloorPopupProps } from "../FactoryFloor.types";
import LineChartMini from "../../../sg-data/LineChartMini/LineChartMini";
import ProgressBar from "../../../sg-data/ProgressBar/ProgressBar";
import Pill from "../../../sg-ui/components/Pill/Pill";

const StyledDiv = styled.div`
position: absolute;
transform: translate(-50%, -100%);
display: flex;
flex-direction: column;
gap: 8px;
`

const StyledMiniChart = styled(LineChartMini)`
height: 28px;
`

const StyledCard = styled(Card)`
padding: 6px;
gap: 8px;
font-size: 12px;

& > .card-title {
    font-size: 12px;
}
`

const FactoryFloorPopup = ({ data }: FactoryFloorPopupProps) => {
    const coordinates = useMemo(() => data?.popupCoordinates, [data?.popupCoordinates]);
    const equipment = useMemo(() => data?.element, [data?.element?._id]);
    const progress = useMemo(() => Math.round(Math.random() * 100), [data?.element?._id]);
    const waiting = useMemo(() => Math.round(Math.random() * 10), [data?.element?._id]);
    const waitingData = useMemo(() => {
        if (waiting === 0) return [1, 2, 4, 3, 1, 2, 1, 0];
        if (waiting === 1) return [0, 2, 4, 3, 2, 2, 2, 1];
        if (waiting === 2) return [1, 2, 4, 3, 3, 2, 5, 2];
        if (waiting === 3) return [0, 1, 1, 3, 5, 2, 6, 3];
        if (waiting === 4) return [1, 2, 2, 3, 3, 4, 5, 4];
        if (waiting === 5) return [0, 1, 0, 0, 0, 2, 4, 5];
        if (waiting === 6) return [0, 2, 4, 2, 4, 6, 6, 6];
        if (waiting === 7) return [1, 3, 5, 8, 9, 8, 8, 7];
        if (waiting === 8) return [1, 2, 4, 3, 5, 2, 6, 8];
        if (waiting === 9) return [0, 1, 1, 3, 4, 6, 8, 9];
        return [3, 6, 7, 10, 16, 11, 12, 10];
    }, [waiting]);

    if (!equipment || !coordinates) return null;

    return (
        <StyledDiv
            className="sg-canvas-popup"
            sg-canvas-x={coordinates.x}
            sg-canvas-y={coordinates.y}
        >
            <StyledCard
                title={equipment._id}
                options={<Pill size="small" color={data?.element?.data?.status === 0 ? 'error' : data?.element?.data?.status === 1 ? 'warning' : 'success'} />}
            >
                <div>
                    <div><strong>Caractéristique 1 :</strong> XXX-XXXX-XXX</div>
                    <div><strong>Caractéristique 2 :</strong> XXX-XXXX-XXX</div>
                    <div><strong>Caractéristique 3 :</strong> XXX-XXXX-XXX</div>
                    <div><strong>Caractéristique 4 :</strong> XXX-XXXX-XXX</div>
                </div>
            </StyledCard>
            <StyledCard title={`En cours : lot 43-DU52`}>
                <ProgressBar progress={progress} />
            </StyledCard>
            <StyledCard title={`En attente : ${waiting}`}>
                <StyledMiniChart data={waitingData} />
            </StyledCard>
        </StyledDiv>
    )
}
export default FactoryFloorPopup;