import {
    SgCanvasClickFunction,
    SgCanvasDrawStyle,
    SgCanvasEventCoordinates,
    SgCanvasMouseEvent,
    SgCanvasOptions,
    SgCanvasWheelFunction,
} from "./SgCanvas.types";
import SgGeometry from "./geometry/SgGeometry.class";
import {
    SgCircle,
    SgGeometryCanvasStyle,
    SgPoint,
    SgPolygon,
    SgPolyline,
    SgRectangle,
    SgSegment,
    SgText,
    SgVector,
} from "./geometry/SgGeometry.interfaces";
import SgGeometryCircle from "./geometry/SgGeometryCircle.class";
import SgGeometryPoint from "./geometry/SgGeometryPoint.class";
import SgGeometryRectangle from "./geometry/SgGeometryRectangle.class";
import SgGeometrySegment from "./geometry/SgGeometrySegment.class";
import { getMouseEventPosition, isTouchEvent } from "./utils/event";

class SgCanvas extends SgGeometryRectangle {
    protected refreshRate?: number;
    private lastRefresh = 0;
    protected autoRefresh = false;
    protected SCROLL_SENSITIVITY = 0.0008;

    // Constructor
    protected ctx: CanvasRenderingContext2D;
    protected canvasRef: HTMLCanvasElement;

    // Drawing area
    private zoom: number = 1;
    private resolution: number = 1;
    private visibleArea!: SgRectangle;
    private offset: SgVector = { x: 0, y: 0 };

    // Settings
    protected options?: Partial<SgCanvasOptions>;
    private margins = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    };

    // Actions
    protected isPanning: boolean = false;
    protected mouseDownEvent?: SgCanvasMouseEvent;
    protected mouseMoveEvent?: SgCanvasMouseEvent;
    protected onClickCb?: SgCanvasClickFunction;
    protected onWheelCb?: SgCanvasWheelFunction;
    protected onMouseDownCb?: SgCanvasClickFunction;
    protected onMouseMoveCb?: SgCanvasClickFunction;
    protected onMouseUpCb?: SgCanvasClickFunction;

    constructor(
        canvasRef: HTMLCanvasElement,
        options?: Partial<SgCanvasOptions>
    ) {
        super({
            x: canvasRef.getBoundingClientRect().x,
            y: canvasRef.getBoundingClientRect().y,
            width: canvasRef.offsetWidth,
            height: canvasRef.offsetHeight,
        });

        // Get ref and context
        this.canvasRef = canvasRef;

        const ctx = canvasRef.getContext("2d");
        if (!ctx) throw new Error();
        this.ctx = ctx;

        // Set options
        this.options = options;
        this.margins = {
            top: options?.margin?.top ?? 0,
            right: options?.margin?.right ?? 0,
            bottom: options?.margin?.bottom ?? 0,
            left: options?.margin?.left ?? 0,
        };
        if (options?.resolution) this.resolution = options.resolution;

        // Update canvas and visible area
        this.sizeCanvas();

        // Add listeners
        this.setClickListener(this.onClick);
        this.setWheelListener(this.onWheel);
        this.setMouseDownListener(this.onMouseDown);
        this.setMouseMoveListener(this.onMouseMove);
        this.setMouseUpListener(this.onMouseUp);

        window.addEventListener("resize", this.onResize.bind(this));
        this.render();
    }

    protected clearWheelListener() {
        if (!this.onWheelCb) return;
        this.canvasRef.removeEventListener("wheel", this.onWheelCb);
    }
    protected setWheelListener(callback: SgCanvasWheelFunction) {
        this.clearWheelListener();
        this.onWheelCb = callback.bind(this);
        this.canvasRef.addEventListener("wheel", this.onWheelCb, {
            passive: true,
        });
    }

    protected clearClickListener() {
        if (!this.onClickCb) return;
        this.canvasRef.removeEventListener("click", this.onClickCb);
    }
    protected setClickListener(callback: SgCanvasClickFunction) {
        this.clearClickListener();
        this.onClickCb = callback.bind(this);
        this.canvasRef.addEventListener("click", this.onClickCb, {
            passive: true,
        });
    }

    protected clearMouseDownListener() {
        if (!this.onMouseDownCb) return;
        this.canvasRef.removeEventListener("mousedown", this.onMouseDownCb);
        this.canvasRef.removeEventListener("touchstart", this.onMouseDownCb);
    }
    protected setMouseDownListener(callback: SgCanvasClickFunction) {
        this.clearMouseDownListener();
        this.onMouseDownCb = callback.bind(this);
        this.canvasRef.addEventListener("mousedown", this.onMouseDownCb, {
            passive: true,
        });
        this.canvasRef.addEventListener("touchstart", this.onMouseDownCb, {
            passive: true,
        });
    }

    protected clearMouseMoveListener() {
        if (!this.onMouseMoveCb) return;
        this.canvasRef.removeEventListener("mousemove", this.onMouseMoveCb);
        this.canvasRef.removeEventListener("touchmove", this.onMouseMoveCb);
    }
    protected setMouseMoveListener(callback: SgCanvasClickFunction) {
        this.clearMouseMoveListener();
        this.onMouseMoveCb = callback.bind(this);
        this.canvasRef.addEventListener("mousemove", this.onMouseMoveCb, {
            passive: true,
        });
        this.canvasRef.addEventListener("touchmove", this.onMouseMoveCb, {
            passive: true,
        });
    }

    protected clearMouseUpListener() {
        if (!this.onMouseUpCb) return;
        this.canvasRef.removeEventListener("mouseup", this.onMouseUpCb);
        this.canvasRef.removeEventListener("touchend", this.onMouseUpCb);
    }
    protected setMouseUpListener(callback: SgCanvasClickFunction) {
        this.clearMouseUpListener();
        this.onMouseUpCb = callback.bind(this);
        this.canvasRef.addEventListener("mouseup", this.onMouseUpCb, {
            passive: true,
        });
        this.canvasRef.addEventListener("touchend", this.onMouseUpCb, {
            passive: true,
        });
    }

    protected clearAllListeners() {
        this.clearWheelListener();
        this.clearClickListener();
        this.clearMouseDownListener();
        this.clearMouseMoveListener();
        this.clearMouseUpListener();
    }

    public destroy() {
        this.clearAllListeners();
    }

    protected sizeCanvas() {
        this.ctx.canvas.width = this.canvasRef.offsetWidth;
        this.ctx.canvas.height = this.canvasRef.offsetHeight;

        this.geometry = {
            x: this.canvasRef?.getBoundingClientRect().x ?? 0,
            y: this.canvasRef?.getBoundingClientRect().y,
            width: this.canvasRef?.offsetWidth ?? 0,
            height: this.canvasRef?.offsetHeight ?? 0,
        };
        this.update();
    }

    protected updatePopups() {
        const popups = this.canvasRef?.parentElement?.querySelectorAll(
            "div.sg-canvas-popup"
        );

        popups?.forEach((popup) => {
            const x = Number(popup.getAttribute("sg-canvas-x"));
            const y = Number(popup.getAttribute("sg-canvas-y"));

            if (!isNaN(x) && !isNaN(y)) {
                const popupDiv = popup as HTMLDivElement;
                const newPosition = this.coordinatesToCanvasCoordinates({
                    x,
                    y,
                });
                popupDiv.style.top = newPosition.y + "px";
                popupDiv.style.left = newPosition.x + "px";
            }
        });
    }

    protected update() {
        super.update();

        if (!this.zoom) this.zoom = 1;
        if (!this.resolution) this.resolution = 1;
        if (!this.offset) this.offset = { x: 0, y: 0 };

        this.visibleArea = {
            ...new SgGeometryPoint({ x: 0, y: 0 })
                .scale(this.resolution * this.zoom)
                .reverseTranslate(this.offset)
                .getGeometry(),
            width: this.geometry.width / (this.resolution * this.zoom),
            height: this.geometry.height / (this.resolution * this.zoom),
        };
    }

    public getVisibleArea(): SgRectangle {
        return this.visibleArea;
    }

    public setZoom(zoom: number) {
        this.zoom = zoom;
        this.update();
    }
    public getZoom(): number {
        return this.zoom;
    }

    public setResolution(resolution: number) {
        this.resolution = resolution;
        this.update();
    }
    public getResolution(): number {
        return this.resolution;
    }

    public getScaleRatio(): number {
        return this.zoom * this.resolution;
    }

    public setOffset(offset: SgVector) {
        this.offset = offset;
        this.update();
    }
    public translateOffset(offset: SgVector) {
        this.offset = SgGeometryPoint.translate(this.offset, offset);
        this.update();
    }
    public getOffset(): SgVector {
        return this.offset;
    }

    public domCoordinatesToCanvasCoordinates(
        coordinates: SgGeometryPoint | SgPoint
    ): SgPoint {
        return SgGeometryPoint.reverseTranslate(coordinates, this.geometry);
    }

    public canvasCoordinatesToDomCoordinates(
        coordinates: SgGeometryPoint | SgPoint
    ): SgPoint {
        return SgGeometryPoint.translate(coordinates, this.geometry);
    }

    public canvasCoordinatesToCoordinates(
        coordinates: SgGeometryPoint | SgPoint
    ): SgPoint {
        return SgGeometryPoint.reverseTranslate(
            SgGeometryPoint.reverseScale(coordinates, this.getScaleRatio()),
            this.offset
        );
    }

    public coordinatesToCanvasCoordinates(
        coordinates: SgGeometryPoint | SgPoint
    ): SgPoint {
        return SgGeometryPoint.scale(
            SgGeometryPoint.translate(coordinates, this.offset),
            this.getScaleRatio()
        );
    }

    public reverseEventCoordinates(
        coordinates: SgGeometryPoint | SgPoint
    ): SgCanvasEventCoordinates {
        const canvasCoordinates =
            this.coordinatesToCanvasCoordinates(coordinates);

        return {
            domCoordinates:
                this.canvasCoordinatesToDomCoordinates(canvasCoordinates),
            canvasCoordinates,
            coordinates: SgGeometryPoint.getGeometry(coordinates),
        };
    }

    protected onResize() {
        this.sizeCanvas();
        this.render();
    }

    protected clear() {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx.clearRect(0, 0, this.geometry.width, this.geometry.height);

        if (this.options?.style?.background) {
            this.ctx.fillStyle = this.options?.style?.background;
            this.drawRect(
                {
                    x: 0,
                    y: 0,
                    width: this.geometry.width,
                    height: this.geometry.height,
                },
                false,
                true
            );
        }
    }

    // Dom
    protected setCursor(cursor?: string) {
        this.canvasRef.style.cursor = cursor ?? "default";
    }

    // Rendering
    protected renderBackground() {}
    protected renderElements() {
        this.setStyle({ lineWidth: 1, fillStyle: "red" });
        new SgGeometryCircle({ x: 0, y: 0, radius: 100 }).draw(this.ctx, {
            stroke: true,
            fill: true,
        });
        new SgGeometryCircle({ x: 0, y: 0, radius: 90 }).draw(this.ctx, {
            stroke: true,
            fill: true,
        });
        new SgGeometrySegment({
            origin: { x: 0, y: 0 },
            end: { x: 100, y: 0 },
        }).draw(this.ctx, {
            stroke: true,
            fill: true,
        });
        new SgGeometrySegment({
            origin: { x: 0, y: 0 },
            end: { x: 0, y: 100 },
        }).draw(this.ctx, {
            stroke: true,
            fill: true,
        });
    }
    protected renderForeground() {}

    protected render(t?: number) {
        if (
            this.autoRefresh &&
            this.refreshRate &&
            t &&
            t - this.lastRefresh < this.refreshRate
        ) {
            window.requestAnimationFrame(this.render.bind(this));
            return;
        }

        this.clear();
        this.ctx.scale(
            this.zoom * this.resolution,
            this.zoom * this.resolution
        );
        // Translate using real coordinates
        this.ctx.translate(this.offset.x, this.offset.y);

        /*   this.setStyle({ fillStyle: "red" });
        SgGeometryRectangle.draw(this.getVisibleArea(), this.ctx, {
            fill: true,
        }); */

        this.renderBackground();
        this.renderElements();
        this.renderForeground();
        this.updatePopups();

        this.lastRefresh = t ?? 0;
        if (this.autoRefresh) {
            window.requestAnimationFrame(this.render.bind(this));
        }
    }

    protected getMouseEvent(
        event: MouseEvent | TouchEvent
    ): SgCanvasMouseEvent {
        const domCoordinates = getMouseEventPosition(event);
        const canvasCoordinates =
            this.domCoordinatesToCanvasCoordinates(domCoordinates);

        return {
            coordinates: this.canvasCoordinatesToCoordinates(canvasCoordinates),
            canvasCoordinates,
            domCoordinates,
            offset: this.offset,
            zoom: this.zoom,
            source: event,
        };
    }

    public setStyle(style: Partial<SgCanvasDrawStyle>) {
        if (style.lineDash) this.ctx.setLineDash(style.lineDash);
        if (style.lineDashOffset)
            this.ctx.lineDashOffset = style.lineDashOffset;

        if (style.fillStyle) this.ctx.fillStyle = style.fillStyle;
        if (style.strokeStyle) this.ctx.strokeStyle = style.strokeStyle;
        if (style.lineCap) this.ctx.lineCap = style.lineCap;
        if (style.lineJoin) this.ctx.lineJoin = style.lineJoin;
        if (style.lineWidth) this.ctx.lineWidth = style.lineWidth;
        if (style.miterLimit) this.ctx.miterLimit = style.miterLimit;

        // Text
        if (style.direction) this.ctx.direction = style.direction;
        if (style.font) this.ctx.font = style.font;
        if (style.fontKerning) this.ctx.fontKerning = style.fontKerning;
        if (style.textAlign) this.ctx.textAlign = style.textAlign;
        if (style.textBaseline) this.ctx.textBaseline = style.textBaseline;
    }

    private strokeAndFill(stroke: boolean = true, fill: boolean = false) {
        if (stroke) this.ctx.stroke();
        if (fill) this.ctx.fill();
    }

    protected drawRect(
        rectangle: SgRectangle,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.rect(
            rectangle.x,
            rectangle.y,
            rectangle.width,
            rectangle.height
        );
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    private drawPoly(
        polyline: SgPolyline,
        close: boolean = false,
        stroke: boolean = true,
        fill: boolean = false
    ) {
        if (!polyline.length) return;

        this.ctx.beginPath();
        this.ctx.moveTo(polyline[0].x, polyline[0].y);
        for (let i = 1; i < polyline.length; i++) {
            this.ctx.lineTo(polyline[i].x, polyline[i].y);
        }
        if (close) this.ctx.lineTo(polyline[0].x, polyline[0].y);
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    protected drawPolyline(
        polyline: SgPolyline,
        stroke: boolean = true,
        fill: boolean = false
    ) {
        this.drawPoly(polyline, false, stroke, fill);
    }

    protected drawPolygon(
        polygon: SgPolygon,
        stroke: boolean = true,
        fill: boolean = false
    ) {
        this.drawPoly(polygon, true, stroke, fill);
    }

    protected drawCircle(
        circle: SgCircle,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        this.ctx.beginPath();
        this.ctx.arc(
            circle.x,
            circle.y,
            Math.floor(circle.radius),
            0,
            2 * Math.PI
        );
        this.ctx.closePath();
        this.strokeAndFill(stroke, fill);
    }

    protected drawLine(line: SgSegment) {
        this.ctx.beginPath();
        this.ctx.moveTo(line.origin.x, line.origin.y);
        this.ctx.lineTo(line.end.x, line.end.y);
        this.strokeAndFill();
        this.ctx.closePath();
    }

    protected drawText(text: SgText) {
        this.ctx.fillText(text.text, text.x, text.y);
    }

    protected drawTextBackground(
        text: SgText,
        fontSize: number,
        paddingH: number,
        paddingV: number,
        stroke: boolean = false,
        fill: boolean = false
    ) {
        const textWidth = this.ctx.measureText(text.text).width;

        const width = textWidth + ((2 * paddingH) / this.getScaleRatio()) * 2;
        const height =
            (fontSize + 2) / this.getScaleRatio() +
            (2 * paddingV) / this.getScaleRatio();
        this.drawRect(
            {
                x: text.x - width / 2,
                y: text.y - height / 2,
                width,
                height,
            },
            stroke,
            fill
        );
    }

    public drawShape(
        shape: SgGeometry,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        shape.clone().scale(this.resolution).draw(ctx, style);
    }

    protected onMouseDown(event: MouseEvent | TouchEvent) {
        const mouseDownEvent = this.getMouseEvent(event);

        if (
            isTouchEvent(event) &&
            event.type === "touchmove" &&
            event.touches.length === 2
        ) {
            const touch1 = {
                x: event.touches[0].clientX,
                y: event.touches[0].clientY,
            };
            const touch2 = {
                x: event.touches[1].clientX,
                y: event.touches[1].clientY,
            };
            this.mouseDownEvent = {
                ...mouseDownEvent,
                pinch: {
                    distance: SgGeometryPoint.distance(touch1, touch2),
                },
            };
        }
        this.mouseDownEvent = mouseDownEvent;
    }

    protected onMouseUp(event: MouseEvent | TouchEvent) {
        this.canvasRef.style.cursor = "default";
        this.isPanning = false;
        this.mouseDownEvent = undefined;
    }

    protected onMouseMove(event: MouseEvent | TouchEvent) {
        if (!this.mouseDownEvent) return;

        if (this.options?.pan || this.options?.zoom) {
            if (
                (!isTouchEvent(event) || event.touches.length === 1) &&
                this.options.pan
            ) {
                this.handlePan(event);
            } else if (
                this.options.zoom &&
                isTouchEvent(event) &&
                event.type === "touchmove" &&
                event.touches.length === 2
            ) {
                this.handlePinchZoom(event);
            }
        }
    }

    protected onClick(event: MouseEvent | TouchEvent) {}

    protected onWheel(e: WheelEvent) {
        if (this.options?.zoom) {
            const zoomAmount = e.deltaY * this.SCROLL_SENSITIVITY;
            if (zoomAmount) {
                const mouseEventBefore = this.getMouseEvent(e);
                this.setZoom((this.zoom += zoomAmount));
                this.translateOffset(
                    SgGeometryPoint.vector(
                        mouseEventBefore.coordinates,
                        this.getMouseEvent(e).coordinates
                    )
                );
            }
        }
    }

    protected handlePan(event: MouseEvent | TouchEvent) {
        if (!this.mouseDownEvent || !this.options?.pan) return;

        this.setCursor("grab");
        this.isPanning = true;
        const coordinates = getMouseEventPosition(event);

        const vector = {
            x:
                (coordinates.x - this.mouseDownEvent.domCoordinates.x) /
                (this.zoom * this.resolution),
            y:
                (coordinates.y - this.mouseDownEvent.domCoordinates.y) /
                (this.zoom * this.resolution),
        };
        this.setOffset(
            SgGeometryPoint.translate(this.mouseDownEvent.offset, vector)
        );
    }

    protected handlePinchZoom(e: TouchEvent) {
        e.preventDefault();

        if (e.touches.length < 2 || !this.mouseDownEvent?.pinch) return;

        const touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY };
        const touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY };

        const distance = SgGeometryPoint.distance(touch1, touch2);

        this.setZoom(
            (this.mouseDownEvent.zoom * distance) /
                this.mouseDownEvent.pinch.distance
        );
    }
}

export default SgCanvas;
