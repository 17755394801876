import styled from "styled-components";
import { LayoutLeftNavProps } from "./LayoutLeftNav.types";

const LayoutLeftNavDiv = styled.div`
display: flex;
widyth: 100%;
height: 100%;
overflow: hidden;
`

const LayoutLeftNav = ({ children }: LayoutLeftNavProps) => <LayoutLeftNavDiv>{children}</LayoutLeftNavDiv>

export default LayoutLeftNav;