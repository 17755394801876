import styled from "styled-components";
import { ReactComponent as DrawIcon } from '../../../assets/icons/draw.svg';
import { ReactComponent as RulerIcon } from '../../../assets/icons/ruler.svg';
import { ReactComponent as SelectIcon } from '../../../assets/icons/select.svg';
import { ButtonIcon, Card } from "../../../sg-ui/components";
import { Icon } from "../../../sg-ui/icons";
import { FactoryFloorEditToolbarProps } from "../FactoryFloorEdit.types";

const StyledCard = styled(Card)`
display: flex;
flex-direction: row;
gap: 8px;
position: absolute;
top: 12px;
left: 50%;
transform: translateX(-50%);
`

const FactoryFloorEditToolbar = ({ action, onAction }: FactoryFloorEditToolbarProps) => {

    return (
        <StyledCard>
            <ButtonIcon
                onClick={() => onAction('edit')}
                icon={<Icon icon={SelectIcon} color={action === 'edit' ? 'accent' : undefined} size="medium" />}
            >
                Editer
            </ButtonIcon>
            <ButtonIcon
                onClick={() => onAction('ruler')}
                icon={<Icon icon={RulerIcon} color={action === 'ruler' ? 'accent' : undefined} size="medium" />}
            >
                Mesurer
            </ButtonIcon>
            <ButtonIcon
                onClick={() => onAction('draw')}
                icon={<Icon icon={DrawIcon} color={action === 'draw' ? 'accent' : undefined} size="medium" />}
            >
                Dessiner
            </ButtonIcon>
        </StyledCard>
    )
}
export default FactoryFloorEditToolbar;