import SgGeometry from "./SgGeometry.class";
import {
    SgGeometryCanvasStyle,
    SgPoint,
    SgVector,
} from "./SgGeometry.interfaces";
import SgGeometryLine from "./SgGeometryLine.class";
import SgGeometrySegment from "./SgGeometrySegment.class";

class SgGeometryPoint extends SgGeometry<SgPoint> {
    constructor(geometry: SgPoint) {
        super(geometry);
        this.update();
    }

    public clone(): SgGeometryPoint {
        return new SgGeometryPoint(this.geometry);
    }

    public scale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryPoint {
        this.setGeometry(SgGeometryPoint.scale(this, coefficient, origin));
        return this;
    }

    public reverseScale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryPoint {
        return this.scale(coefficient === 0 ? 1 : 1 / coefficient, origin);
    }

    public translate(vector: SgVector): SgGeometryPoint {
        this.setGeometry(SgGeometryPoint.translate(this, vector));
        return this;
    }

    public move(to: SgGeometryPoint | SgPoint): SgGeometryPoint {
        this.setGeometry(SgGeometryPoint.move(this, to));
        return this;
    }

    public rotate(
        angle: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryPoint {
        this.setGeometry(SgGeometryPoint.rotate(this, angle, origin));
        return this;
    }

    public equals(point: SgGeometryPoint | SgPoint): boolean {
        return SgGeometryPoint.equals(this, point);
    }

    public isAround(
        point: SgGeometryPoint | SgPoint,
        delta: number = 0
    ): boolean {
        return SgGeometryPoint.isAround(this, point, delta);
    }

    public isInside(point: SgGeometryPoint | SgPoint): boolean {
        return SgGeometryPoint.isInside(this, point);
    }

    protected update() {
        this.origin = this;
        this.centroid = this;
        this.edges = [];
        this.vertices = [];
        this.bounds = {
            xMin: this.geometry.x,
            xMax: this.geometry.x,
            yMin: this.geometry.y,
            yMax: this.geometry.y,
        };
        this.boundingBox = {
            x: this.geometry.x,
            y: this.geometry.y,
            width: this.geometry.x,
            height: this.geometry.y,
        };
    }

    public draw(
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {}

    public getVerticalLine(): SgGeometryLine {
        return new SgGeometryLine({
            origin: this.geometry,
            end: { ...this.geometry, y: this.geometry.y - 100 },
        });
    }

    public getHorizontalLine(): SgGeometryLine {
        return new SgGeometryLine({
            origin: this.geometry,
            end: { ...this.geometry, x: this.geometry.x - 100 },
        });
    }

    public vector(point: SgPoint | SgGeometryPoint): SgVector {
        return SgGeometryPoint.vector(this.geometry, point);
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return SgGeometryPoint.distance(this, point);
    }

    static isInside(
        point1: SgGeometryPoint | SgPoint,
        point2: SgGeometryPoint | SgPoint
    ): boolean {
        return this.isAround(point1, point2, 0);
    }

    static isAround(
        point1: SgGeometryPoint | SgPoint,
        point2: SgGeometryPoint | SgPoint,
        delta: number = 0
    ): boolean {
        return this.distance(point1, point2) <= delta;
    }

    static scale(
        point: SgGeometryPoint | SgPoint,
        coefficient: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgPoint {
        const p = this.getGeometry(point);
        const o = SgGeometryPoint.getGeometry(origin);

        return {
            x: (p.x - o.x) * coefficient + o.x,
            y: (p.y - o.y) * coefficient + o.x,
        };
    }

    static reverseScale(
        point: SgGeometryPoint | SgPoint,
        coefficient: number
    ): SgPoint {
        return this.scale(point, coefficient === 0 ? 1 : 1 / coefficient);
    }

    static translate(
        point: SgGeometryPoint | SgPoint,
        vector: SgVector
    ): SgPoint {
        const p = this.getGeometry(point);
        return {
            x: p.x + vector.x,
            y: p.y + vector.y,
        };
    }

    static reverseTranslate(
        point: SgGeometryPoint | SgPoint,
        vector: SgVector
    ): SgPoint {
        return this.translate(point, { x: -vector.x, y: -vector.y });
    }

    static move(
        point: SgGeometryPoint | SgPoint,
        to: SgGeometryPoint | SgPoint
    ): SgPoint {
        return this.getGeometry(to);
    }

    static rotate(
        point: SgGeometryPoint | SgPoint,
        angle: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgPoint {
        const angleRad = angle * (Math.PI / 180);
        const p = this.getGeometry(point);
        const o = this.getGeometry(origin);

        const x1 = p.x - o.x;
        const y1 = p.y - o.y;

        const x2 = x1 * Math.cos(angleRad) - y1 * Math.sin(angleRad);
        const y2 = x1 * Math.sin(angleRad) + y1 * Math.cos(angleRad);

        return {
            x: x2 + o.x,
            y: y2 + o.y,
        };
    }

    static draw(
        point: SgGeometryPoint | SgPoint,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {}

    static equals(
        point1: SgGeometryPoint | SgPoint,
        point2: SgGeometryPoint | SgPoint
    ): boolean {
        const p1 = this.getGeometry(point1);
        const p2 = this.getGeometry(point2);
        return p1.x === p2.x && p1.y === p2.y;
    }

    static getGeometry(point: SgGeometryPoint | SgPoint): SgPoint {
        return point instanceof SgGeometryPoint ? point.getGeometry() : point;
    }

    static vector(
        point1: SgPoint | SgGeometryPoint,
        point2: SgPoint | SgGeometryPoint
    ): SgVector {
        const p1 = this.getGeometry(point1);
        const p2 = this.getGeometry(point2);

        return { x: p2.x - p1.x, y: p2.y - p1.y };
    }

    static distance(
        point1: SgPoint | SgGeometryPoint,
        point2: SgPoint | SgGeometryPoint
    ): number {
        const p1 = this.getGeometry(point1);
        const p2 = this.getGeometry(point2);
        return SgGeometrySegment.mesure({ origin: p1, end: p2 });
    }
}

export default SgGeometryPoint;
