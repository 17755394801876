import { useEffect, useRef } from "react";
import { LineChartMiniProps } from "./LineChartMini.types";
import styled, { useTheme } from "styled-components";
import SgGeometrySegment from "../../sg-canvas/geometry/SgGeometrySegment.class";

const StyledCanvas = styled.canvas`
height: 100%;
width: 100%;
`

const LineChartMini = ({ data, ...props }: LineChartMiniProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const theme = useTheme();

    useEffect(() => {
        if (canvasRef.current && data.length > 1) {
            const ctx = canvasRef.current.getContext("2d");

            if (ctx) {
                const width = canvasRef.current.offsetWidth;
                const height = canvasRef.current.offsetHeight;
                ctx.canvas.width = width;
                ctx.canvas.height = height;
                ctx.clearRect(0, 0, width, height);

                const resolution = {
                    x: width / (data.length - 1),
                    y: (height - 4) / Math.max(...data)
                }

                for (let i = 1; i < data.length; i++) {
                    const segment = new SgGeometrySegment({
                        origin: { x: (i - 1) * resolution.x, y: (height - 2) - data[i - 1] * resolution.y },
                        end: { x: i * resolution.x, y: (height - 2) - data[i] * resolution.y }
                    });

                    ctx.strokeStyle = theme.colors.pristine;
                    ctx.fillStyle = 'rgba(38, 154, 200, 0.5)';
                    ctx.lineWidth = 2;
                    segment.draw(ctx);


                    ctx.beginPath();
                    ctx.moveTo(segment.getGeometry().origin.x, segment.getGeometry().origin.y);
                    ctx.lineTo(segment.getGeometry().end.x, segment.getGeometry().end.y);
                    ctx.lineTo(segment.getGeometry().end.x, height - 2);
                    ctx.lineTo(segment.getGeometry().origin.x, height - 2);
                    ctx.lineTo(segment.getGeometry().origin.x, segment.getGeometry().origin.y);
                    ctx.closePath();
                    ctx.fill();
                }
            }
        }
    }, [canvasRef, data]);


    return (
        <StyledCanvas ref={canvasRef} className="line-chart-mini" {...props} />
    )
}
export default LineChartMini;