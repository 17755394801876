import SgCanvas from "../sg-canvas/SgCanvas";
import { SgPoint, SgVector } from "../sg-canvas/geometry/SgGeometry.interfaces";
import SgGeometryLine from "../sg-canvas/geometry/SgGeometryLine.class";
import SgGeometryPoint from "../sg-canvas/geometry/SgGeometryPoint.class";
import SgGeometryRectangle from "../sg-canvas/geometry/SgGeometryRectangle.class";
import SgGeometrySegment from "../sg-canvas/geometry/SgGeometrySegment.class";
import {
    SgSchematicsElement,
    SgSchematicsElementSelectedData,
    SgSchematicsOptions,
} from "./SgSchematics.types";

class SgSchematics extends SgCanvas {
    // Options
    protected options?: Partial<SgSchematicsOptions>;

    // Magnet
    private MAGNET_SENSIBILITY = 5;
    private magnetLines?: SgSchematicsElement<SgGeometryLine>[];
    private magnetPoints?: SgSchematicsElement<SgGeometryPoint>[];
    private activeMagnetLines?: SgSchematicsElement<SgGeometryLine>[];
    private activeMagnetPoint?: SgSchematicsElement<SgGeometryPoint>;

    // Elements
    private elements: SgSchematicsElement[] = [];
    private visibleElements: SgSchematicsElement[] = [];
    protected elementOver?: SgSchematicsElement;
    protected elementSelected?: SgSchematicsElement;
    protected ELEMENT_VERTEX_RADIUS: number = 6;

    // Actions
    protected mode: string = "view";
    protected actionsPerMode?: {
        crosshair: string[];
        grid: string[];
        drawLine: string[];
        addLine: string[];
        magnets: string[];
        select: string[];
        over: string[];
        move: string[];
    } = {
        crosshair: ["edit", "ruler", "draw"],
        grid: ["view", "edit", "ruler", "draw"],
        drawLine: ["draw", "ruler"],
        addLine: ["draw"],
        magnets: ["edit", "draw", "ruler"],
        select: ["edit", "view"],
        over: ["edit", "view"],
        move: ["edit"],
    };
    protected newLine?: SgSchematicsElement<SgGeometrySegment>;
    protected elementMove?: {
        initialVector: SgVector;
        onMove: (coordinates: SgPoint) => void;
    };
    public onSelectElement?: (data?: SgSchematicsElementSelectedData) => void;

    constructor(
        canvasRef: HTMLCanvasElement,
        options?: Partial<SgSchematicsOptions>
    ) {
        super(canvasRef, options);
        this.options = options;
        this.autoRefresh = true;
        this.mode = options?.mode ?? "view";
        this.render();
    }

    // Elements
    public setElements(elements: SgSchematicsElement[]) {
        this.elements = elements;
        this.update();
    }
    public getElements(): SgSchematicsElement[] {
        return this.elements;
    }
    public addElement(element: SgSchematicsElement): void {
        this.elements.push(element);
        this.update();
    }
    public getVisibleElements(): SgSchematicsElement[] {
        return this.visibleElements;
    }
    public getElementOver(): SgSchematicsElement | undefined {
        return this.elementOver;
    }
    protected updateElementOver(coordinates: SgPoint) {
        if (!this.actionsPerMode?.over.includes(this.mode)) return;

        const elementOver = this.visibleElements.find((e) =>
            e.shape.isAround(coordinates, 5 / this.getScaleRatio())
        );

        if (this.elementOver?._id !== elementOver?._id) {
            this.elementOver = elementOver;
        }
    }
    protected updateElementSelected() {
        if (
            !this.actionsPerMode?.select.includes(this.mode) ||
            this.elementSelected?._id === this.elementOver?._id
        )
            return;

        this.elementSelected = this.elementOver;
        if (this.onSelectElement) {
            const popupCoordinates = this.elementSelected?.shape
                .getCentroid()
                .clone()
                .translate({
                    x: 0,
                    y: -this.elementSelected.shape.getBoundingBox().height / 2,
                })
                .getGeometry();

            this.onSelectElement({
                element: this.elementSelected,
                popupCoordinates,
            });
        }
    }

    protected updateElementMove(coordinates: SgPoint) {
        if (
            !this.actionsPerMode?.move.includes(this.mode) ||
            !this.elementSelected
        )
            return;
        if (this.elementMove) {
            this.setCursor("move");
            this.elementMove.onMove(coordinates);
        } else {
            this.setCursor();
        }
    }

    // Actions
    public setMode(mode: string) {
        this.mode = mode;
        this.cancel();
    }
    private initElementMove(coordinates: SgPoint) {
        if (!this.actionsPerMode?.move.includes(this.mode)) return;
        const coordinatesWithMagnet =
            this.getCoordinatesWithMagnets(coordinates);
        if (this.elementSelected?.shape instanceof SgGeometrySegment) {
            if (
                SgGeometryPoint.isAround(
                    coordinatesWithMagnet,
                    this.elementSelected.shape.getGeometry().end,
                    5 / this.getScaleRatio()
                )
            ) {
                this.elementMove = {
                    initialVector: { x: 0, y: 0 },
                    onMove: (coordinates: SgPoint) =>
                        this.handleMoveSegmentVertex(coordinates, "end"),
                };
            } else if (
                SgGeometryPoint.isAround(
                    coordinatesWithMagnet,
                    this.elementSelected.shape.getGeometry().origin,
                    5 / this.getScaleRatio()
                )
            ) {
                this.elementMove = {
                    initialVector: { x: 0, y: 0 },
                    onMove: (coordinates: SgPoint) =>
                        this.handleMoveSegmentVertex(coordinates, "origin"),
                };
            }
        } else if (
            this.elementSelected?.shape.isAround(
                coordinatesWithMagnet,
                5 / this.getScaleRatio()
            )
        ) {
            this.elementMove = {
                initialVector: SgGeometryPoint.vector(
                    coordinates,
                    this.elementSelected.shape.getOrigin()
                ),
                onMove: (coordinates: SgPoint) =>
                    this.handleMoveShape(coordinates),
            };
        }
    }
    private handleMoveSegmentVertex(
        coordinates: SgPoint,
        vertex: "origin" | "end"
    ) {
        if (!(this.elementSelected?.shape instanceof SgGeometrySegment)) return;
        this.updateMagnets(coordinates);
        const magnetCoordinates = this.getCoordinatesWithMagnets(coordinates);
        this.elementSelected?.shape.setGeometry(
            vertex === "end"
                ? {
                      origin: this.elementSelected.shape.getGeometry().origin,
                      end: magnetCoordinates,
                  }
                : {
                      origin: magnetCoordinates,
                      end: this.elementSelected.shape.getGeometry().end,
                  }
        );
    }
    private handleMoveShape(coordinates: SgPoint) {
        if (!this.elementSelected || !this.elementMove) return;

        this.elementSelected.shape
            .translate(
                SgGeometryPoint.vector(
                    this.elementSelected.shape.getOrigin(),
                    coordinates
                )
            )
            .translate(this.elementMove.initialVector);

        const vertices = this.elementSelected.shape.getVertices();

        for (let vertex of vertices) {
            this.updateMagnets(vertex.getGeometry());

            if (this.activeMagnetPoint) {
                this.elementSelected.shape.translate(
                    SgGeometryPoint.vector(
                        vertex.getGeometry(),
                        this.activeMagnetPoint.shape.getGeometry()
                    )
                );
                return;
            }
        }
        for (let vertex of vertices) {
            this.updateMagnets(vertex.getGeometry());

            if (this.activeMagnetLines?.length) {
                this.elementSelected.shape.translate(
                    SgGeometryPoint.vector(
                        vertex.getGeometry(),
                        this.getCoordinatesWithMagnets(vertex.getGeometry())
                    )
                );
                return;
            }
        }
    }

    // Flow
    protected update() {
        super.update();

        this.visibleElements =
            this.getElements()?.filter((e) =>
                SgGeometryRectangle.overlap(
                    this.getVisibleArea(),
                    e.shape.getBoundingBox()
                )
            ) ?? [];

        this.magnetPoints = this.visibleElements
            .map((e) =>
                e.shape.getVertices().map((v) => ({
                    _id: e._id,
                    shape: v,
                }))
            )
            .flat();

        this.magnetLines = this.visibleElements
            .map((e) => [
                ...e.shape.getEdges().map((edge) => ({
                    _id: e._id,
                    shape: edge.getLine(),
                })),
                ...e.shape.getVertices().map((v, i) => ({
                    _id: e._id,
                    shape: v.getHorizontalLine(),
                })),
                ...e.shape.getVertices().map((v, i) => ({
                    _id: e._id,
                    shape: v.getVerticalLine(),
                })),
            ])
            .flat();
    }

    protected cancel() {
        this.elementOver = undefined;
        this.elementSelected = undefined;
        this.activeMagnetLines = undefined;
        this.activeMagnetPoint = undefined;
        this.newLine = undefined;
        this.elementMove = undefined;
    }

    // Render
    protected drawVertexHandle(
        vertex: SgPoint | SgGeometryPoint,
        color: string
    ) {
        const scaleRatio = this.getScaleRatio();
        this.setStyle({
            lineWidth: 1 / scaleRatio,
            lineDash: [],
            lineCap: "square",
            strokeStyle: color,
            fillStyle: "white",
        });
        this.drawCircle(
            {
                ...SgGeometryPoint.getGeometry(vertex),
                radius: this.ELEMENT_VERTEX_RADIUS / scaleRatio,
            },
            true,
            true
        );
    }

    private drawGrid() {
        if (!this.actionsPerMode?.grid.includes(this.mode)) return;

        const color = this.options?.style?.grid ?? "lightgrey";
        const visibleArea = this.getVisibleArea();
        const visibleAreaBounds = SgGeometryRectangle.bounds(visibleArea);
        const scaleRatio = this.getScaleRatio();

        // Draw origin
        this.setStyle({
            strokeStyle: color,
            lineWidth: 2 / scaleRatio,
            lineDash: [],
        });

        this.drawLine({
            origin: { x: 0, y: visibleAreaBounds.yMin },
            end: {
                x: 0,
                y: visibleAreaBounds.yMax,
            },
        });
        this.drawLine({
            origin: { x: visibleAreaBounds.xMin, y: 0 },
            end: {
                x: visibleAreaBounds.xMax,
                y: 0,
            },
        });

        // Draw grid
        this.setStyle({
            strokeStyle: color,
            fillStyle: color,
            font: `${12 / scaleRatio}px sans-serif`,
            lineWidth: 1 / scaleRatio,
            textAlign: "left",
            textBaseline: "top",
        });

        const pixelsPerMeter = scaleRatio * 100;

        const tick =
            pixelsPerMeter > 40
                ? 100
                : pixelsPerMeter > 20
                ? 200
                : pixelsPerMeter > 8
                ? 500
                : pixelsPerMeter > 4
                ? 1000
                : 2000;

        // Vertical lines
        for (
            let i = Math.ceil(visibleAreaBounds.xMin / tick) - 1;
            i < Math.ceil(visibleAreaBounds.xMax / tick) + 1;
            i++
        ) {
            this.drawLine({
                origin: { x: i * tick, y: visibleAreaBounds.yMin },
                end: {
                    x: i * tick,
                    y: visibleAreaBounds.yMax,
                },
            });
            this.drawText({
                text: (i * tick) / 100 + "m",
                x: i * tick + 4 / this.getZoom(),
                y: visibleAreaBounds.yMin + 4 / this.getZoom(),
            });
        }

        // Horizontal lines
        this.setStyle({
            textAlign: "left",
            textBaseline: "bottom",
        });
        for (
            let i = Math.ceil(visibleAreaBounds.yMin / tick) - 1;
            i < Math.ceil(visibleAreaBounds.yMax / tick) + 1;
            i++
        ) {
            this.drawLine({
                origin: { x: visibleAreaBounds.xMin, y: i * tick },
                end: {
                    x: visibleAreaBounds.xMax,
                    y: i * tick,
                },
            });
            this.drawText({
                text: (i * tick) / 100 + "m",
                x: visibleAreaBounds.xMin + 4 / this.getZoom(),
                y: i * tick - 2 / this.getZoom(),
            });
        }
    }

    private drawCrosshair() {
        if (
            !this.actionsPerMode?.crosshair.includes(this.mode) ||
            !this.mouseMoveEvent
        )
            return;

        const color = this.options?.style?.crosshair ?? "lightslategrey";
        const visibleAreaBounds = SgGeometryRectangle.bounds(
            this.getVisibleArea()
        );
        const scaleRatio = this.getScaleRatio();
        const coordinates = this.getCoordinatesWithMagnets(
            this.mouseMoveEvent.coordinates
        );

        // Draw origin
        this.setStyle({
            strokeStyle: color,
            lineWidth: 1 / scaleRatio,
            fillStyle: color,
            font: `${12 / scaleRatio}px sans-serif`,
            textAlign: "left",
            textBaseline: "top",
        });

        this.drawLine({
            origin: {
                x: coordinates.x,
                y: visibleAreaBounds.yMin,
            },
            end: {
                x: coordinates.x,
                y: visibleAreaBounds.yMin + 20 / scaleRatio,
            },
        });
        this.drawText({
            text: (coordinates.x / 100).toFixed(2) + "m",
            x: coordinates.x + 4 / this.getZoom(),
            y: visibleAreaBounds.yMin + 4 / this.getZoom(),
        });
        this.drawLine({
            origin: {
                x: visibleAreaBounds.xMin,
                y: coordinates.y,
            },
            end: {
                x: visibleAreaBounds.xMin + 50 / scaleRatio,
                y: coordinates.y,
            },
        });
        this.setStyle({
            textAlign: "left",
            textBaseline: "bottom",
        });
        this.drawText({
            text: (coordinates.y / 100).toFixed(2) + "m",
            x: visibleAreaBounds.xMin + 4 / this.getZoom(),
            y: coordinates.y - 4 / this.getZoom(),
        });
    }

    protected renderBackground() {
        super.renderBackground();
        this.drawGrid();
    }

    protected renderElements() {
        super.renderElements();
        this.setStyle({
            lineWidth: 2 / this.getScaleRatio(),
            lineDash: [],
            lineCap: "square",
            strokeStyle: this.options?.style?.line ?? "dimgrey",
            fillStyle: "white",
        });
        this.elements?.forEach((e) => {
            e.shape.draw(this.ctx);
        });
    }

    protected renderForeground() {
        super.renderForeground();
        this.drawCrosshair();

        const scaleRatio = this.getScaleRatio();

        // Element over
        if (this.elementOver) {
            this.setStyle({
                strokeStyle:
                    this.options?.style?.selectedElement ?? "deepskyblue",
                lineWidth: 2 / this.getScaleRatio(),
            });
            this.elementOver.shape.draw(this.ctx, { stroke: true });
        }

        // Element selected
        if (this.elementSelected) {
            this.setStyle({
                strokeStyle:
                    this.options?.style?.selectedElement ?? "dodgerblue",
                lineWidth: 2 / this.getScaleRatio(),
            });
            this.elementSelected.shape.draw(this.ctx, { stroke: true });

            if (this.elementSelected.shape instanceof SgGeometrySegment) {
                this.elementSelected.shape
                    .getVertices()
                    .forEach((v) =>
                        this.drawVertexHandle(
                            v,
                            this.options?.style?.selectedElement ?? "dodgerblue"
                        )
                    );
            }
        }

        // Draw magnets
        if (this.activeMagnetLines?.length) {
            this.setStyle({
                strokeStyle: "grey",
                fillStyle: "white",
                lineWidth: 1 / scaleRatio,
                lineDash: [10 / scaleRatio, 10 / scaleRatio],
            });
            this.activeMagnetLines.forEach((l) => {
                SgGeometryRectangle.project(
                    this.getVisibleArea(),
                    l.shape
                )?.draw(this.ctx);
            });
        }

        // Draw newLine
        if (this.newLine) {
            this.setStyle({
                lineWidth: 1 / scaleRatio,
                lineDash: [],
                lineCap: "square",
                strokeStyle: this.options?.style?.newLine ?? "orange",
                fillStyle: "white",
            });
            this.newLine.shape.draw(this.ctx);
            this.drawVertexHandle(
                this.newLine.shape.getOrigin().getGeometry(),
                this.options?.style?.newLine ?? "orange"
            );

            this.setStyle({
                fillStyle: "white",
                strokeStyle: "grey",
                lineWidth: 1 / scaleRatio,
            });
            const text = {
                text:
                    (this.newLine.shape.getLength() / 100) // todo unit
                        .toFixed(2) + "m",
                ...this.newLine.shape.getCentroid().getGeometry(),
            };
            this.drawTextBackground(text, 14, 4, 2, true, true);

            this.setStyle({
                fillStyle: "grey",
                font: `${14 / scaleRatio}px sans-serif`,
                textAlign: "center",
                textBaseline: "middle",
            });
            this.drawText(text);
        }

        if (this.activeMagnetPoint) {
            this.drawVertexHandle(
                this.activeMagnetPoint.shape.getGeometry(),
                "grey"
            );
        }
    }

    // Coordinates
    protected updateMagnets(coordinates: SgPoint) {
        if (!this.actionsPerMode?.magnets.includes(this.mode)) {
            if (this.activeMagnetPoint || this.activeMagnetLines?.length) {
                this.activeMagnetPoint = undefined;
                this.activeMagnetLines = undefined;
                this.setCursor("default");
            }
            return;
        }
        const filteredIds = this.elementSelected
            ? [this.elementSelected._id]
            : [];
        const scaleRatio = this.getScaleRatio();
        const points = this.magnetPoints
            ?.filter(
                (p) =>
                    p.shape.isAround(
                        coordinates,
                        this.MAGNET_SENSIBILITY / scaleRatio
                    ) && !filteredIds?.includes(p._id)
            )
            .sort((p1, p2) =>
                p1.shape.distance(coordinates) < p2.shape.distance(coordinates)
                    ? -1
                    : 1
            );
        const point = points?.length ? points[0] : undefined;

        const lines = !point
            ? this.magnetLines
                  ?.filter(
                      (p) =>
                          p.shape.distance(coordinates) <
                              this.MAGNET_SENSIBILITY / scaleRatio &&
                          !filteredIds?.includes(p._id)
                  )
                  .sort((p1, p2) =>
                      p1.shape.distance(coordinates) <
                      p2.shape.distance(coordinates)
                          ? -1
                          : 1
                  )
                  .filter(
                      (l1, i1, lines) =>
                          !lines.some(
                              (l2, i2) =>
                                  l2.shape.isParallel(l1.shape) && i2 < i1
                          )
                  )
                  .slice(0, 2)
            : [];

        if (
            point?._id !== this.activeMagnetPoint?._id ||
            lines?.length !== this.activeMagnetLines?.length ||
            lines?.some(
                (l) => !this.activeMagnetLines?.find((al) => al._id === l._id)
            )
        ) {
            this.activeMagnetPoint = point;
            this.activeMagnetLines = lines;
        }
    }

    protected getCoordinatesWithMagnets(coordinates: SgPoint): SgPoint {
        if (this.activeMagnetPoint)
            return this.activeMagnetPoint.shape.getGeometry();
        if (this.activeMagnetLines?.length === 2) {
            return (
                SgGeometryLine.intersect(
                    this.activeMagnetLines[0].shape,
                    this.activeMagnetLines[1].shape
                ) ?? coordinates
            );
        } else if (this.activeMagnetLines?.length === 1) {
            return (
                this.activeMagnetLines[0].shape.getIntersection(
                    this.activeMagnetLines[0].shape.getPerpendicular(
                        coordinates
                    )
                ) ?? coordinates
            );
        }

        return coordinates;
    }

    // Events
    protected onMouseDown(event: MouseEvent | TouchEvent): void {
        super.onMouseDown(event);

        // if element selected, try start move
        if (this.elementSelected && this.mouseDownEvent) {
            this.initElementMove(this.mouseDownEvent.coordinates);
        }
    }

    protected onMouseMove(event: MouseEvent | TouchEvent): void {
        this.mouseMoveEvent = this.getMouseEvent(event);

        if (this.elementMove) {
            this.updateElementMove(this.mouseMoveEvent.coordinates);
            return;
        }

        // Magnets
        this.updateMagnets(this.mouseMoveEvent.coordinates);
        const coordinates = this.getCoordinatesWithMagnets(
            this.mouseMoveEvent.coordinates
        );

        // Elements
        this.updateElementOver(coordinates);

        // New line
        this.newLine?.shape.setEnd(coordinates);

        if (this.elementOver) {
            this.setCursor("pointer");
        } else if (this.mode === "ruler" || this.mode === "draw") {
            this.setCursor("crosshair");
        } else {
            this.setCursor();
        }

        super.onMouseMove(event);
    }

    protected onMouseUp(event: MouseEvent | TouchEvent) {
        if (!this.isPanning) {
            if (this.elementMove) {
                // If element moving, end movement
                this.elementMove = undefined;
                this.update();
            } else if (this.actionsPerMode?.drawLine.includes(this.mode)) {
                // If new line, create/end new line
                const mouseEvent = this.getMouseEvent(event);

                if (!this.newLine) {
                    const coordinates = this.getCoordinatesWithMagnets(
                        mouseEvent.coordinates
                    );
                    this.newLine = {
                        _id: "new-wall" + Math.random() * 1000,
                        shape: new SgGeometrySegment({
                            origin: coordinates,
                            end: coordinates,
                        }),
                    };
                } else if (this.newLine) {
                    if (this.actionsPerMode?.addLine.includes(this.mode)) {
                        this.addElement(this.newLine);
                    }
                    this.newLine = undefined;
                }
            } else {
                this.updateElementSelected();
            }
        }

        super.onMouseUp(event);
    }
}

export default SgSchematics;
