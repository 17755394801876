import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";
import { SgCircle, SgPoint, SgRectangle } from "../../sg-canvas/geometry/SgGeometry.interfaces";
import SgFactory from "../../sg-factory/SgFactory.class";
import { SgFactoryElement } from "../../sg-factory/SgFactory.types";
import { Page } from "../../sg-ui/components";
import { useUiContext } from "../../sg-ui/contexts";
import { PLAN, getBaths, getOptions } from "../../utils/mocks";
import FactoryFloorToolbar from "./components/FactoryFloorEditToolbar";

const PageDiv = styled(Page)`
position: relative;
padding: 0;
`

const FactoryFloorEdit = () => {
    const theme = useTheme();
    const { isDarkMode } = useUiContext();
    const [mode, setMode] = useState<string>('edit');
    const canvasDomRef = useRef<HTMLCanvasElement>(null);
    const factoryRef = useRef<SgFactory | null>(null);
    const [isInit, setInit] = useState<boolean>(false);
    const [equipmentOverEvent, setEquipmentOverEvent] = useState<{ equipment: SgFactoryElement<SgRectangle | SgCircle>, center: SgPoint } | null>(null);

    const equipments = useMemo(() => getBaths(), []);

    const handleAction = useCallback((a: string) => {
        if (factoryRef.current) {
            factoryRef.current.setMode(a);
            setMode(a);
        }
    }, []);

    const handleMouseMove = useCallback((event: MouseEvent) => {
        //if (!floorPlanRef.current) return;

        /* const elementOver = floorPlanRef.current.getElementOver(event as any, ['equipment']) ?? null;

            <FloorPlan
                equipments={equipments}
                plan={PLAN}
                options={getOptions(theme, isDarkMode)}
            />
            
        setEquipmentOverEvent(elementOver?.type === 'equipment' ? { equipment: elementOver.element as FloorPlanEquipment, center: elementOver.center.canvasDomCoordinates } : null); */
    }, []);

    useEffect(() => {
        if (canvasDomRef.current) {
            factoryRef.current = new SgFactory(
                canvasDomRef.current,
                {
                    ...getOptions(theme, isDarkMode),
                    mode: 'edit',
                    walls: PLAN?.walls,
                    equipments: equipments,
                    zones: PLAN?.zones
                },
            );
        }

        return () => {
            factoryRef.current?.destroy()
        }
    }, []);


    return (
        <PageDiv>
            <canvas
                className="floor-plan-canvas"
                ref={canvasDomRef}
                style={{ width: '100%', height: '100%' }}
            />
            <FactoryFloorToolbar action={mode} onAction={handleAction} />
        </PageDiv>
    )
}
export default FactoryFloorEdit;