export { ReactComponent as AnalyseIcon } from './assets/analyse.svg';
export { ReactComponent as BulletIcon } from './assets/bullet.svg';
export { ReactComponent as CalendarTimeIcon } from './assets/calendar-time.svg';
export { ReactComponent as CalendarIcon } from './assets/calendar.svg';
export { ReactComponent as DownIcon } from './assets/chevron-down.svg';
export { ReactComponent as LeftIcon } from './assets/chevron-left.svg';
export { ReactComponent as RightIcon } from './assets/chevron-right.svg';
export { ReactComponent as UpIcon } from './assets/chevron-up.svg';
export { ReactComponent as CloseIcon } from './assets/close.svg';
export { ReactComponent as ConnectIcon } from './assets/connect.svg';
export { ReactComponent as DataBarIcon } from './assets/data-bar.svg';
export { ReactComponent as DragHandleIcon } from './assets/drag-handle.svg';
export { ReactComponent as EditIcon } from './assets/edit.svg';
export { ReactComponent as EmailReadIcon } from './assets/email-read.svg';
export { ReactComponent as EnergyIcon } from './assets/energy.svg';
export { ReactComponent as ErrorIcon } from './assets/error.svg';
export { ReactComponent as FilterIcon } from './assets/filter.svg';
export { ReactComponent as FormIcon } from './assets/form.svg';
export { ReactComponent as BackwardIcon } from './assets/frexy-arrow-backward.svg';
export { ReactComponent as ForwardIcon } from './assets/frexy-arrow-forward.svg';
export { ReactComponent as BadgeIcon } from './assets/frexy-badge.svg';
export { ReactComponent as BrushIcon } from './assets/frexy-brush.svg';
export { ReactComponent as BubbleIcon } from './assets/frexy-bubble.svg';
export { ReactComponent as BuildingIcon } from './assets/frexy-building.svg';
export { ReactComponent as BulletCheckedIcon } from './assets/frexy-bullet-checked.svg';
export { ReactComponent as CheckIcon } from './assets/frexy-check.svg';
export { ReactComponent as CircleHelpIcon } from './assets/frexy-circle-help.svg';
export { ReactComponent as CityIcon } from './assets/frexy-city.svg';
export { ReactComponent as ConnectPolygonIcon } from './assets/frexy-connect-polygon.svg';
export { ReactComponent as ConnectStarIcon } from './assets/frexy-connect-star.svg';
export { ReactComponent as CrosshairIcon } from './assets/frexy-crosshair.svg';
export { ReactComponent as DownloadIcon } from './assets/frexy-data-arrow.svg';
export { ReactComponent as DocumentCheckedIcon } from './assets/frexy-document-checked.svg';
export { ReactComponent as DocumentForwardIcon } from './assets/frexy-document-forward.svg';
export { ReactComponent as DocumentHelpIcon } from './assets/frexy-document-help.svg';
export { ReactComponent as EraseIcon } from './assets/frexy-erase.svg';
export { ReactComponent as FactoryIcon } from './assets/frexy-factory.svg';
export { ReactComponent as FileErrorIcon } from './assets/frexy-file-error.svg';
export { ReactComponent as FileSearchIcon } from './assets/frexy-file-search.svg';
export { ReactComponent as GlobeWireIcon } from './assets/frexy-globe-wire.svg';
export { ReactComponent as GlobeIcon } from './assets/frexy-globe.svg';
export { ReactComponent as HandIcon } from './assets/frexy-hand.svg';
export { ReactComponent as KnifeIcon } from './assets/frexy-knife.svg';
export { ReactComponent as LockIcon } from './assets/frexy-lock.svg';
export { ReactComponent as EmailIcon } from './assets/frexy-mail.svg';
export { ReactComponent as MapIcon } from './assets/frexy-map.svg';
export { ReactComponent as ReloadIcon } from './assets/frexy-reload.svg';
export { ReactComponent as SortDownIcon } from './assets/frexy-sort-down.svg';
export { ReactComponent as SortUpIcon } from './assets/frexy-sort-up.svg';
export { ReactComponent as SortIcon } from './assets/frexy-sort.svg';
export { ReactComponent as SquareFourIcon } from './assets/frexy-square-four.svg';
export { ReactComponent as TrashIcon } from './assets/frexy-trash.svg';
export { ReactComponent as TubeIcon } from './assets/frexy-tube.svg';
export { ReactComponent as UnlockIcon } from './assets/frexy-unlock.svg';
export { ReactComponent as ViewIcon } from './assets/frexy-view.svg';
export { ReactComponent as HamburgerIcon } from './assets/hamburger.svg';
export { ReactComponent as HideIcon } from './assets/hide.svg';
export { ReactComponent as HomeIcon } from './assets/home.svg';
export { ReactComponent as HourglassIcon } from './assets/hour-glass.svg';
export { ReactComponent as ImageIcon } from './assets/image.svg';
export { ReactComponent as InfoIcon } from './assets/info.svg';
export { ReactComponent as LinkIcon } from './assets/link.svg';
export { ReactComponent as LinkedInIcon } from './assets/linkedin.svg';
export { ReactComponent as LocationIcon } from './assets/location.svg';
export { ReactComponent as LogoutIcon } from './assets/logout.svg';
export { ReactComponent as MagnifierIcon } from './assets/magnifier.svg';
export { ReactComponent as MarketIcon } from './assets/market.svg';
export { ReactComponent as MenuIcon } from './assets/menu.svg';
export { ReactComponent as MergeIcon } from './assets/merge.svg';
export { ReactComponent as MessageIcon } from './assets/message.svg';
export { ReactComponent as MinusSquareIcon } from './assets/minus-square.svg';
export { ReactComponent as MinusIcon } from './assets/minus.svg';
export { ReactComponent as NetworkIcon } from './assets/network.svg';
export { ReactComponent as KeyIcon } from './assets/key.svg';
export { ReactComponent as NewsIcon } from './assets/news.svg';
export { ReactComponent as NotificationIcon } from './assets/notification.svg';
export { ReactComponent as WarningIcon } from './assets/warning.svg';
export { ReactComponent as OptionsIcon } from './assets/options.svg';
export { ReactComponent as OfficeIcon } from './assets/office.svg';
export { ReactComponent as OpenIcon } from './assets/open.svg';
export { ReactComponent as PhoneIcon } from './assets/phone.svg';
export { ReactComponent as PictureIcon } from './assets/picture.svg';
export { ReactComponent as PlusSquareIcon } from './assets/plus-square.svg';
export { ReactComponent as PlusIcon } from './assets/plus.svg';
export { ReactComponent as PositionIcon } from './assets/position.svg';
export { ReactComponent as QuestionIcon } from './assets/question.svg';
export { ReactComponent as SaveAsIcon } from './assets/save-as.svg';
export { ReactComponent as SaveIcon } from './assets/save.svg';
export { ReactComponent as SearchGlobeWireIcon } from './assets/search-globe-wire.svg';
export { ReactComponent as SearchGlobeIcon } from './assets/search-globe.svg';
export { ReactComponent as SearchIcon } from './assets/search.svg';
export { ReactComponent as SettingIcon } from './assets/settings.svg';
export { ReactComponent as SgIcon } from './assets/sg.svg';
export { ReactComponent as ShowIcon } from './assets/show.svg';
export { ReactComponent as SpinngerIcon } from './assets/spinner.svg';
export { ReactComponent as SuccessIcon } from './assets/success.svg';
export { ReactComponent as SurveyIcon } from './assets/survey.svg';
export { ReactComponent as TasksIcon } from './assets/tasks.svg';
export { ReactComponent as TreeIcon } from './assets/tree.svg';
export { ReactComponent as TypingIcon } from './assets/typing.svg';
export { ReactComponent as UndoIcon } from './assets/undo.svg';
export { ReactComponent as UploadIcon } from './assets/upload.svg';
export { ReactComponent as UserDeleteIcon } from './assets/user-delete.svg';
export { ReactComponent as UserGroupIcon } from './assets/user-group.svg';
export { ReactComponent as UserMinusIcon } from './assets/user-minus.svg';
export { ReactComponent as UserPlusIcon } from './assets/user-plus.svg';
export { ReactComponent as UserSettingIcon } from './assets/user-setting.svg';
export { ReactComponent as UserTieIcon } from './assets/user-tie.svg';
export { ReactComponent as UsersIcon } from './assets/users.svg';
export { ReactComponent as WindTurbine } from './assets/wind-turbine.svg';
export { ReactComponent as WindTurbines } from './assets/wind-turbines.svg';
export { ReactComponent as WorkspaceIcon } from './assets/workspace.svg';
export { ReactComponent as XIcon } from './assets/x.svg';
export { default as Icon } from './Icon/Icon';

