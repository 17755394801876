export const theme = (isDarkMode: boolean): Record<string, any> => ({
    colors: {
        primary: "#3b6d70", //pantone-p-123-15-U
        secondary: "#353535", //pantone-p-179-15-C
        tertiary: "#284b63", //pantone-2168-C
        accent: "#ef9e1d", //pantone-1375-C
        accent1: "#fca211",
        accent2: "#fca211", //pantone-1375-C
        accent3: "#fca211", //pantone-1375-C
        accent4: "#29b4ec",
        grey: "#d9d9d9", //pantone-p-179-2-C
        white: "#f4f4f4",
        black: "#010508",
        greyLightest: "rgb(247, 247, 248)",
        greyLighter: "rgb(241, 243, 239)",
        greyLight: "rgb(223, 225, 231)",
        greyMedium: "rgb(152, 155, 162)",
        greyDark: "rgb(94, 94, 94)",
        greyDarker: "rgb(60, 64, 74)",
        greyDarkest: "rgb(38, 41, 47)",
        error: "rgb(189, 42, 42)",
        warning: "rgb(215, 133, 61)",
        success: "rgb(44, 174, 63)",
        pristine: "#269ac8",
        palette1: "#9e0142",
        palette2: "#c43546",
        palette3: "#dd5a32",
        palette4: "#da9c5f",
        palette5: "#d8d657",
        palette6: "#b3c364",
        palette7: "#83b07d",
        palette8: "#5ba78f",
        palette9: "#2d6e96",
        palette10: "#504389",
    },
    layout: {
        background: isDarkMode ? "rgb(43, 45, 48)" : "rgb(240, 240, 248)",
        padding: "12px",
        gap: "12px",
    },
    text: {
        color: isDarkMode ? "#f4f4f4" : "#010508",
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    container: {
        borderRadius: "4px",
        gap: "12px",
        padding: "12px",
        backgroundColor: isDarkMode ? "rgb(48, 51, 56)" : "rgb(247, 247, 248)",
        backgroundImage: isDarkMode
            ? "linear-gradient(178deg, rgb(48, 51, 56) 60%, rgb(47, 49, 54) 80%, rgb(45, 48, 53) 100%)"
            : "linear-gradient(178deg, rgb(247, 247, 248) 60%, rgb(245, 245, 243) 80%, rgb(239, 243, 240) 100%)",
        boxShadow: isDarkMode
            ? "rgba(0, 0, 0, 0.36) 0px 15px 36px 0px, rgba(0, 0, 0, 0.08) 0px 0px 8px 8px"
            : "rgba(178, 178, 178, 0.16) 0px 10px 24px 0px, rgba(163, 163, 163, 0.2) 0px 0px 10px 1px",
    },
});
