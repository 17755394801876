import SgGeometry from "./SgGeometry.class";
import {
    SgBounds,
    SgGeometryCanvasStyle,
    SgLine,
    SgPoint,
    SgRectangle,
    SgSegment,
    SgVector,
} from "./SgGeometry.interfaces";
import SgGeometryLine from "./SgGeometryLine.class";
import SgGeometryPoint from "./SgGeometryPoint.class";
import SgGeometrySegment from "./SgGeometrySegment.class";

class SgGeometryRectangle extends SgGeometry<SgRectangle> {
    constructor(geometry: SgRectangle) {
        super(geometry);
        this.update();
    }

    public clone(): SgGeometryRectangle {
        return new SgGeometryRectangle(this.geometry);
    }

    public getDimensions(): { width: number; height: number } {
        return {
            width: this.geometry.width,
            height: this.geometry.height,
        };
    }

    protected update() {
        this.origin = new SgGeometryPoint(this.geometry);
        this.centroid = new SgGeometryPoint(SgGeometryRectangle.centroid(this));
        this.vertices = SgGeometryRectangle.vertices(this).map(
            (v) => new SgGeometryPoint(v)
        );
        this.edges = SgGeometryRectangle.edges(this).map(
            (e) => new SgGeometrySegment(e)
        );
        this.bounds = SgGeometryRectangle.bounds(this);
        this.boundingBox = this.getGeometry();
    }

    public getNamedVertices(): {
        topLeft: SgGeometryPoint;
        topRight: SgGeometryPoint;
        bottomRight: SgGeometryPoint;
        bottomLeft: SgGeometryPoint;
    } {
        return {
            topLeft: this.vertices[0],
            topRight: this.vertices[1],
            bottomRight: this.vertices[2],
            bottomLeft: this.vertices[3],
        };
    }

    public getNamedEdges(): {
        top: SgGeometrySegment;
        right: SgGeometrySegment;
        bottom: SgGeometrySegment;
        left: SgGeometrySegment;
    } {
        return {
            top: this.edges[0],
            right: this.edges[1],
            bottom: this.edges[2],
            left: this.edges[3],
        };
    }

    public scale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryRectangle {
        this.setGeometry(SgGeometryRectangle.scale(this, coefficient, origin));
        this.update();
        return this;
    }

    public reverseScale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryRectangle {
        return this.scale(coefficient === 0 ? 1 : 1 / coefficient, origin);
    }

    public translate(vector: SgVector): SgGeometryRectangle {
        this.setGeometry(SgGeometryRectangle.translate(this, vector));
        this.update();
        return this;
    }

    public reverseTranslate(vector: SgVector): SgGeometryRectangle {
        return this.translate({ x: -vector.x, y: -vector.y });
    }

    public move(to: SgGeometryPoint | SgPoint): SgGeometryRectangle {
        this.setGeometry(SgGeometryRectangle.move(this, to));
        this.update();
        return this;
    }

    public rotate(
        angle: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometryRectangle {
        this.setGeometry(
            SgGeometryRectangle.rotate(this, angle, origin ?? this.centroid)
        );
        this.update();
        return this;
    }

    public equals(rectangle: SgRectangle | SgGeometryRectangle): boolean {
        return SgGeometryRectangle.equals(this, rectangle);
    }

    public isInside(point: SgPoint): boolean {
        return SgGeometryRectangle.isInside(this, point);
    }

    public isAround(point: SgPoint, delta: number = 0): boolean {
        return SgGeometryRectangle.isAround(this, point, delta);
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return SgGeometryRectangle.distance(this, point);
    }

    public draw(
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        SgGeometryRectangle.draw(this, ctx, style);
    }

    public project(
        line: SgLine | SgGeometryLine
    ): SgGeometrySegment | undefined {
        return SgGeometryRectangle.project(this, line);
    }

    public overlap(rectangle: SgRectangle | SgGeometryRectangle): boolean {
        return SgGeometryRectangle.overlap(this, rectangle);
    }

    static vertices(rectangle: SgRectangle | SgGeometryRectangle): SgPoint[] {
        const r = this.getGeometry(rectangle);

        return [
            {
                x: r.x,
                y: r.y,
            },
            {
                x: r.x + r.width,
                y: r.y,
            },
            {
                x: r.x + r.width,
                y: r.y + r.height,
            },
            {
                x: r.x,
                y: r.y + r.height,
            },
        ];
    }

    static edges(rectangle: SgRectangle | SgGeometryRectangle): SgSegment[] {
        const r = this.getGeometry(rectangle);

        return [
            { origin: { x: r.x, y: r.y }, end: { x: r.x + r.width, y: r.y } },
            {
                origin: { x: r.x + r.width, y: r.y },
                end: { x: r.x + r.width, y: r.y + r.height },
            },
            {
                origin: { x: r.x + r.width, y: r.y + r.height },
                end: { x: r.x, y: r.y + r.height },
            },
            { origin: { x: r.x, y: r.y + r.height }, end: { x: r.x, y: r.y } },
        ];
    }

    static centroid(rectangle: SgRectangle | SgGeometryRectangle): SgPoint {
        const r = this.getGeometry(rectangle);
        return (
            SgGeometryLine.intersect(
                SgGeometryLine.equation({
                    origin: { x: r.x, y: r.y },
                    end: { x: r.x + r.width, y: r.y + r.height },
                }),
                SgGeometryLine.equation({
                    origin: { x: r.x + r.width, y: r.y },
                    end: { x: r.x, y: r.y + r.height },
                })
            ) ?? { x: 0, y: 0 }
        );
    }

    static bounds(rectangle: SgRectangle | SgGeometryRectangle): SgBounds {
        const r = this.getGeometry(rectangle);
        return {
            xMin: r.x,
            xMax: r.x + r.width,
            yMin: r.y,
            yMax: r.y + r.height,
        };
    }

    static isAround(
        rectangle: SgRectangle | SgGeometryRectangle,
        point: SgPoint,
        delta: number = 0
    ): boolean {
        if (this.isInside(rectangle, point)) return true;

        const edges =
            rectangle instanceof this
                ? rectangle.getEdges()
                : this.edges(rectangle).map((e) => new SgGeometrySegment(e));

        return edges
            .map((e) => e.isAround(point, delta))
            .some((b) => b === true);
    }

    static isInside(
        rectangle: SgRectangle | SgGeometryRectangle,
        point: SgPoint | SgGeometryPoint
    ): boolean {
        const edges =
            rectangle instanceof this
                ? rectangle.getEdges()
                : this.edges(rectangle);
        const equations = edges.map((e) => SgGeometryLine.equation(e));

        return equations.every((e) => SgGeometryLine.compute(e, point) >= 0);
    }

    static scale(
        rectangle: SgRectangle | SgGeometryRectangle,
        coefficient: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgRectangle {
        const r = this.getGeometry(rectangle);
        const o = SgGeometryPoint.getGeometry(origin);

        return {
            ...SgGeometryPoint.scale({ x: r.x, y: r.y }, coefficient),
            width: r.width * coefficient,
            height: r.height * coefficient,
        };
    }

    static reverseScale(
        rectangle: SgRectangle | SgGeometryRectangle,
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgRectangle {
        return this.scale(
            rectangle,
            coefficient === 0 ? 1 : 1 / coefficient,
            origin
        );
    }

    static translate(
        rectangle: SgRectangle | SgGeometryRectangle,
        vector: SgVector
    ): SgRectangle {
        const r = this.getGeometry(rectangle);
        return {
            ...r,
            ...SgGeometryPoint.translate({ x: r.x, y: r.y }, vector),
        };
    }

    static reverseTranslate(
        rectangle: SgRectangle | SgGeometryRectangle,
        vector: SgVector
    ): SgRectangle {
        return this.translate(rectangle, { x: -vector.x, y: -vector.y });
    }

    static move(
        rectangle: SgRectangle | SgGeometryRectangle,
        point: SgGeometryPoint | SgPoint
    ): SgRectangle {
        const r = this.getGeometry(rectangle);
        return this.translate(
            rectangle,
            SgGeometryPoint.vector({ x: r.x, y: r.y }, point)
        );
    }

    static rotate(
        rectangle: SgRectangle | SgGeometryRectangle,
        angle: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgRectangle {
        const r = this.getGeometry(rectangle);

        return r;
    }

    static equals(
        rectangle1: SgRectangle | SgGeometryRectangle,
        rectangle2: SgRectangle | SgGeometryRectangle
    ): boolean {
        const r1 = this.getGeometry(rectangle1);
        const r2 = this.getGeometry(rectangle2);
        return (
            r1.x === r2.x &&
            r1.y === r2.y &&
            r1.width === r2.width &&
            r1.height === r2.height
        );
    }

    static getGeometry(
        rectangle: SgRectangle | SgGeometryRectangle
    ): SgRectangle {
        return rectangle instanceof SgGeometry
            ? rectangle.getGeometry()
            : rectangle;
    }

    static mesure(rectangle: SgRectangle | SgGeometryRectangle): number {
        const s = this.getGeometry(rectangle);
        return s.height * s.width;
    }

    static distance(
        rectangle: SgRectangle | SgGeometryRectangle,
        point: SgPoint | SgGeometryPoint
    ): number {
        if (SgGeometryRectangle.isInside(rectangle, point)) return 0;

        const edges =
            rectangle instanceof this
                ? rectangle.getEdges()
                : this.edges(rectangle);

        return Math.min(
            ...edges.map((e) => SgGeometrySegment.distance(e, point))
        );
    }

    static draw(
        rectangle: SgRectangle | SgGeometryRectangle,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        const r = this.getGeometry(rectangle);
        ctx.beginPath();
        ctx.rect(r.x, r.y, r.width, r.height);
        if (style?.stroke) ctx.stroke();
        if (style?.fill) ctx.fill();
        ctx.closePath();
    }

    static project(
        rectangle: SgRectangle | SgGeometryRectangle,
        line: SgLine | SgGeometryLine
    ): SgGeometrySegment | undefined {
        const r =
            rectangle instanceof SgGeometryRectangle
                ? rectangle
                : new SgGeometryRectangle(rectangle);
        const equation =
            line instanceof SgGeometryLine ? line.getEquation() : line;
        const edges = r.getEdges();

        const intersections = edges
            .map((side) => SgGeometryLine.intersect(side.getLine(), equation))
            .filter(
                (point) => point !== undefined && r.isAround(point, 5)
            ) as SgPoint[];

        return intersections.length >= 2
            ? new SgGeometrySegment({
                  origin: intersections[0],
                  end: intersections[1],
              })
            : undefined;
    }

    static overlap(
        rectangle1: SgRectangle | SgGeometryRectangle,
        rectangle2: SgRectangle | SgGeometryRectangle
    ): boolean {
        const r1 = this.getGeometry(rectangle1);
        const r2 = this.getGeometry(rectangle2);
        return (
            r1.x < r2.x + r2.width &&
            r1.x + r1.width > r2.x &&
            r1.y < r2.y + r2.height &&
            r1.y + r1.height > r2.y
        );
    }
}

export default SgGeometryRectangle;
