import { ComponentType, useEffect, useRef, useState } from "react";
import { WithTransitionComponentProps, WithTransitionProps, WithTransitionStatus } from "./withTransition.types";

const withTransition = <P,>(
    WrappedComponent: ComponentType<P & WithTransitionComponentProps>,
    timeout: { enter?: number, exit?: number }
): React.FC<P & WithTransitionProps> => {
    return (props: P & WithTransitionProps) => {
        const [status, setStatus] = useState<WithTransitionStatus>('enter');
        const timeoutRef = useRef<NodeJS.Timeout | null>(null);

        useEffect(() => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);

            if (status === 'enter' && timeout.enter) {
                timeoutRef.current = setTimeout(() => {
                    setStatus('idle');
                    if (props.onTransitionEnter) {
                        props.onTransitionEnter();
                    }
                }, timeout.enter);
            } else if (status === 'exit' && timeout.exit) {
                timeoutRef.current = setTimeout(() => {
                    setStatus('exited');
                    if (props.onTransitionExit) {
                        props.onTransitionExit();
                    }
                }, timeout.enter);
            }

            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
            }
        }, [status]);

        return <WrappedComponent {...(props as P)} transitionStatus={status} setTransitionStatus={setStatus} />
    };
}

export default withTransition;