import styled from 'styled-components';
import { Color } from '../../types/styling.types';
import { CardProps } from './Card.types';

const CardDiv = styled.div<{ $borderColor?: Color }>`
border-radius: ${(props) => props.theme.container.borderRadius};
display: flex;
flex-direction: column;
gap: ${(props) => props.theme.container.gap};
padding: ${(props) => props.theme.container.padding};
background-color: ${(props) => props.theme.container.backgroundColor};
background-image: ${(props) => props.theme.container.backgroundImage};
border: ${(props) => props.color && props.theme.colors[props.color] ? props.theme.colors[props.color] : 'transparent'} 1px solid;
box-shadow: ${(props) => props.theme.container.boxShadow};
height: fit-content;
position: relative;

& > .card-title {
    color: ${(props) => props.theme.colors.greyMedium};
    font-size: 14px;
    font-weight: normal;
    font-family: Montserrat;
    margin: 0;
    display: flex;
    align-items: center;

    & > span {
        flex-grow: 1;
    }
}
`;

const Card = ({
    children,
    borderColor,
    title,
    options,
    ...props
}: CardProps) => (
    <CardDiv className="card" $borderColor={borderColor} {...props}>
        {!!title && (
            <div className="card-title">
                <span>{title}</span>
                {options}
            </div>
        )}
        {children}
    </CardDiv>
);

export default Card;
