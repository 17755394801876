export const isTouchEvent = (
    event: TouchEvent | MouseEvent
): event is TouchEvent =>
    ["touchstart", "touchmove", "touchend"].includes(event.type);

export const getMouseEventPosition = (
    event: MouseEvent | TouchEvent
): { x: number; y: number } => {
    let x = 0;
    let y = 0;
    if (isTouchEvent(event)) {
        if (event.touches?.length) {
            const touch = event.touches[0];
            x = touch.pageX;
            y = touch.pageY;
        }
    } else {
        x = event.clientX;
        y = event.clientY;
    }
    return {
        x,
        y,
    };
};

export const getMouseEventDomElementPosition = (
    coordinates: { x: number; y: number },
    domElement: HTMLElement
): { x: number; y: number } => ({
    x: coordinates.x - domElement.getBoundingClientRect().x,
    y: coordinates.y - domElement.getBoundingClientRect().y,
});

export const getMouseEventPositionFromDomElementPosition = (
    coordinates: { x: number; y: number },
    domElement: HTMLElement
): { x: number; y: number } => ({
    x: coordinates.x + domElement.getBoundingClientRect().x,
    y: coordinates.y + domElement.getBoundingClientRect().y,
});
