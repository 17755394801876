
import Button from "./Button";
import { ButtonIconProps } from "./Button.types";

const ButtonIcon = ({ icon, ...props }: ButtonIconProps) => (
    <Button {...props}>
        {!props.loading && icon}
        {props.children}
    </Button>
)

export default ButtonIcon;