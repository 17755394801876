import {
    SgCircle,
    SgLine,
    SgRectangle,
    SgSimpleGeometry,
} from "../geometry/SgGeometry.interfaces";

export const isType = <T>(
    obj: any,
    callback: (obj: any) => boolean
): obj is T => callback(obj);

export const isCircle = (shape: SgSimpleGeometry) =>
    isType<SgCircle>(shape, (s) => s?.hasOwnProperty("radius"));
export const isRectangle = (shape: SgSimpleGeometry) =>
    isType<SgRectangle>(shape, (s) => s?.hasOwnProperty("width"));
export const isLine = (shape: SgSimpleGeometry) =>
    isType<SgLine>(shape, (s) => s?.hasOwnProperty("end"));
