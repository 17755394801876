import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { UiContextReturnInterface, UiProviderProps } from "./UiProvider.types";
import '../../styles/fonts.css'

export const UiContext = createContext<UiContextReturnInterface>({
    toggleDarkMode: () => null,
    setDarkMode: () => null,
    isDarkMode: false,
});

const GlobalStyle = createGlobalStyle`
html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    cursor: default;
    font-size: ${(props) => props.theme.text.fontSize};
    font-family: ${(props) => props.theme.text.fontFamily};
    color: ${(props) => props.theme.text.color};
    background: ${(props) => props.theme.layout.background};

    & * {
        box-sizing: border-box;
    }
    
    scrollbar-width: thin;
    scrollbar-color: transparent;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.primary};
        border-radius: 4px;
    }
}

a,
.link {
    text-decoration: none;
    font-weight: 500;
    color: ${(props) => props.theme.text.color};
    cursor: pointer;
    font-size: 12px;

    &:hover {
        text-decoration: underline;
    }
}
`

const UiProvider = ({ theme, children }: UiProviderProps) => {
    const [isDarkMode, setDarkMode] = useState<boolean>(false);
    const { i18n } = useTranslation();
    const [isInit, setInit] = useState<boolean>(false);

    const themeWithMode = useMemo(() => theme(isDarkMode), [theme, isDarkMode]);

    useEffect(() => {
        const language = (['en', 'fr'].includes(navigator.language) ? navigator.language : 'en');

        if (i18n.language !== language) {
            i18n.changeLanguage(language)
        }

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            //setDarkMode(true);
        }

        if (!isInit) setInit(true);
    }, []);

    if (!isInit) return null;

    return (
        <UiContext.Provider value={{ isDarkMode, setDarkMode, toggleDarkMode: () => setDarkMode(isDarkMode => !isDarkMode) }}>
            <ThemeProvider theme={themeWithMode}>
                <GlobalStyle />
                {children}
            </ThemeProvider>
        </UiContext.Provider>
    );
};

const useUiContext = () => {
    return useContext(UiContext);
};

export { UiProvider, useUiContext };
