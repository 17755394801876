import { default as SgGeometry } from "./SgGeometry.class";
import {
    SgGeometryCanvasStyle,
    SgPoint,
    SgSegment,
    SgVector,
} from "./SgGeometry.interfaces";
import SgGeometryLine from "./SgGeometryLine.class";
import SgGeometryPoint from "./SgGeometryPoint.class";

class SgGeometrySegment extends SgGeometry<SgSegment> {
    private length!: number;
    private line!: SgGeometryLine;

    constructor(geometry: SgSegment) {
        super(geometry);
        this.update();
    }

    public clone(): SgGeometrySegment {
        return new SgGeometrySegment(this.geometry);
    }

    public getLength(): number {
        return this.length;
    }

    public getLine(): SgGeometryLine {
        return this.line;
    }

    public setOrigin(origin: SgGeometryPoint | SgPoint): SgGeometrySegment {
        this.setGeometry({
            origin: SgGeometryPoint.getGeometry(origin),
            end: this.geometry.end,
        });
        this.update();
        return this;
    }

    public setEnd(end: SgGeometryPoint | SgPoint): SgGeometrySegment {
        this.setGeometry({
            origin: this.geometry.origin,
            end: SgGeometryPoint.getGeometry(end),
        });
        this.update();
        return this;
    }

    public scale(coefficient: number): SgGeometrySegment {
        this.setGeometry(SgGeometrySegment.scale(this, coefficient));
        this.update();
        return this;
    }

    public reverseScale(coefficient: number): SgGeometrySegment {
        return this.scale(coefficient === 0 ? 1 : 1 / coefficient);
    }

    public translate(vector: SgVector): SgGeometrySegment {
        this.setGeometry(SgGeometrySegment.translate(this, vector));
        this.update();
        return this;
    }

    public move(to: SgGeometryPoint | SgPoint): SgGeometrySegment {
        this.setGeometry(SgGeometrySegment.move(this, to));
        this.update();
        return this;
    }

    public rotate(
        angle: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometrySegment {
        this.setGeometry(
            SgGeometrySegment.rotate(this, angle, origin ?? this.centroid)
        );
        this.update();
        return this;
    }

    public equals(segment: SgGeometrySegment | SgSegment): boolean {
        return SgGeometrySegment.equals(this, segment);
    }

    public isInside(point: SgPoint | SgGeometryPoint): boolean {
        return SgGeometrySegment.isInside(this, point);
    }

    public isAround(
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return SgGeometrySegment.isAround(this, point, delta);
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return SgGeometrySegment.distance(this, point);
    }

    protected update() {
        this.origin = new SgGeometryPoint(this.geometry.origin);
        this.centroid = new SgGeometryPoint(
            SgGeometrySegment.centroid(this.geometry)
        );
        this.edges = [this];
        this.vertices = [this.origin, new SgGeometryPoint(this.geometry.end)];
        this.bounds = {
            xMin: Math.min(this.geometry.origin.x, this.geometry.end.x),
            xMax: Math.max(this.geometry.origin.x, this.geometry.end.x),
            yMin: Math.min(this.geometry.origin.y, this.geometry.end.y),
            yMax: Math.max(this.geometry.origin.y, this.geometry.end.y),
        };
        this.boundingBox = {
            x: this.bounds.xMin,
            y: this.bounds.yMin,
            width: this.bounds.xMax - this.bounds.xMin,
            height: this.bounds.yMax - this.bounds.yMin,
        };
        this.length = SgGeometrySegment.mesure(this);
        this.line = new SgGeometryLine(this.geometry);
    }

    public draw(
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        SgGeometrySegment.draw(this, ctx, style);
    }

    static centroid(segment: SgGeometrySegment | SgSegment): SgPoint {
        const s = this.getGeometry(segment);
        return {
            x: (s.origin.x + s.end.x) / 2,
            y: (s.origin.y + s.end.y) / 2,
        };
    }

    static isAround(
        segment: SgGeometrySegment | SgSegment,
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        const length =
            segment instanceof SgGeometry
                ? segment.getLength()
                : this.mesure(segment);
        const s = this.getGeometry(segment);
        const equation =
            segment instanceof SgGeometrySegment
                ? segment.getLine().getEquation()
                : SgGeometryLine.equation(segment);

        return (
            SgGeometryLine.distance(equation, point) <= delta &&
            SgGeometryPoint.distance(s.origin, point) <= length + delta &&
            SgGeometryPoint.distance(s.end, point) <= length + delta
        );
    }

    static isInside(
        segment: SgGeometrySegment | SgSegment,
        point: SgPoint | SgGeometryPoint
    ): boolean {
        const equation =
            segment instanceof SgGeometrySegment
                ? segment.getLine().getEquation()
                : SgGeometryLine.equation(segment);

        if (SgGeometryLine.compute(equation, point) !== 0) return false;

        const s = this.getGeometry(segment);
        const length = this.mesure(segment);

        return (
            SgGeometryPoint.distance(point, s.origin) <= length &&
            SgGeometryPoint.distance(point, s.end) <= length
        );
    }

    static scale(
        segment: SgGeometrySegment | SgSegment,
        coefficient: number
    ): SgSegment {
        const s = this.getGeometry(segment);
        return {
            origin: SgGeometryPoint.scale(s.origin, coefficient),
            end: SgGeometryPoint.scale(s.end, coefficient),
        };
    }

    static reverseScale(
        segment: SgGeometrySegment | SgSegment,
        coefficient: number
    ): SgSegment {
        return this.scale(segment, coefficient === 0 ? 1 : 1 / coefficient);
    }

    static translate(
        segment: SgGeometrySegment | SgSegment,
        vector: SgVector
    ): SgSegment {
        const s = this.getGeometry(segment);
        return {
            origin: SgGeometryPoint.translate(s.origin, vector),
            end: SgGeometryPoint.translate(s.end, vector),
        };
    }

    static move(
        segment: SgGeometrySegment | SgSegment,
        point: SgGeometryPoint | SgPoint
    ): SgSegment {
        const s = this.getGeometry(segment);
        return this.translate(segment, SgGeometryPoint.vector(s.origin, point));
    }

    static rotate(
        segment: SgGeometrySegment | SgSegment,
        angle: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgSegment {
        const s = this.getGeometry(segment);

        return {
            origin: SgGeometryPoint.rotate(s.origin, angle, origin),
            end: SgGeometryPoint.rotate(s.end, angle, origin),
        };
    }

    static draw(
        segment: SgGeometrySegment | SgSegment,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {
        const s = this.getGeometry(segment);
        ctx.beginPath();
        ctx.moveTo(s.origin.x, s.origin.y);
        ctx.lineTo(s.end.x, s.end.y);
        ctx.stroke();
        ctx.closePath();
    }

    static equals(
        segment1: SgGeometrySegment | SgSegment,
        segment2: SgGeometrySegment | SgSegment
    ): boolean {
        const s1 = this.getGeometry(segment1);
        const s2 = this.getGeometry(segment2);
        return (
            SgGeometryPoint.equals(s1.origin, s2.origin) &&
            SgGeometryPoint.equals(s1.end, s2.end)
        );
    }

    static getGeometry(segment: SgGeometrySegment | SgSegment): SgSegment {
        return segment instanceof SgGeometry ? segment.getGeometry() : segment;
    }

    static mesure(segment: SgGeometrySegment | SgSegment): number {
        const s = this.getGeometry(segment);
        return Math.sqrt(
            Math.pow(s.end.x - s.origin.x, 2) +
                Math.pow(s.end.y - s.origin.y, 2)
        );
    }

    static distance(
        segment: SgGeometrySegment | SgSegment,
        point: SgPoint | SgGeometryPoint
    ): number {
        const length = this.mesure(segment);
        const p = SgGeometryPoint.getGeometry(point);
        const equation =
            segment instanceof SgGeometrySegment
                ? segment.getLine().getEquation()
                : SgGeometryLine.equation(segment);
        const perpendicular = SgGeometryLine.perpendicular(equation, p);
        const intersection = SgGeometryLine.intersect(equation, perpendicular);

        if (!intersection) return 0;

        const s = this.getGeometry(segment);
        if (
            SgGeometryPoint.distance(intersection, s.origin) <= length &&
            SgGeometryPoint.distance(intersection, s.end) <= length
        ) {
            return SgGeometryLine.distance(equation, point);
        }
        return Math.min(
            SgGeometryPoint.distance(point, s.origin),
            SgGeometryPoint.distance(point, s.end)
        );
    }
}

export default SgGeometrySegment;
