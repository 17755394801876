import {
    SgBounds,
    SgGeometryCanvasStyle,
    SgPoint,
    SgRectangle,
    SgSimpleGeometry,
    SgVector,
} from "./SgGeometry.interfaces";
import SgGeometryPoint from "./SgGeometryPoint.class";
import SgGeometryRectangle from "./SgGeometryRectangle.class";
import SgGeometrySegment from "./SgGeometrySegment.class";

abstract class SgGeometry<T = SgSimpleGeometry> {
    protected geometry!: T;
    protected origin!: SgGeometryPoint;
    protected centroid!: SgGeometryPoint;
    protected edges!: SgGeometrySegment[];
    protected vertices!: SgGeometryPoint[];
    protected bounds!: SgBounds;
    protected boundingBox!: SgRectangle;

    constructor(geometry: T) {
        this.setGeometry(geometry);
    }

    public clone(): SgGeometry<T> {
        return this;
    }

    public getGeometry(): T {
        return this.geometry;
    }
    public setGeometry(geometry: T): SgGeometry<T> {
        this.geometry = geometry;
        this.update();
        return this;
    }

    public getOrigin(): SgGeometryPoint {
        return this.origin;
    }

    public getBounds(): SgBounds {
        return this.bounds;
    }

    public getBoundingBox(): SgRectangle {
        return this.boundingBox;
    }

    public getVertices(): SgGeometryPoint[] {
        return this.vertices;
    }

    public getEdges(): SgGeometrySegment[] {
        return this.edges;
    }

    public getCentroid(): SgGeometryPoint {
        return this.centroid;
    }

    public scale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometry<T> {
        this.update();
        return this;
    }

    public reverseScale(
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgGeometry<T> {
        return this.scale(coefficient === 0 ? 1 : 1 / coefficient, origin);
    }

    public translate(vector: SgVector): SgGeometry<T> {
        this.update();
        return this;
    }

    public reverseTranslate(vector: SgVector): SgGeometry<T> {
        return this.translate({ x: -vector.x, y: -vector.y });
    }

    public move(to: SgGeometryPoint | SgPoint): SgGeometry<T> {
        this.update();
        return this;
    }

    public rotate(
        angle: number,
        origin: SgGeometryPoint | SgPoint
    ): SgGeometry<T> {
        this.update();
        return this;
    }

    public equals(geometry: T | SgGeometry<T>): boolean {
        return false;
    }

    public isInside(
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return this.isAround(point, 0);
    }

    public isAround(
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return false;
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return 0;
    }

    protected update() {}

    public draw(
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {}

    static clone(geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>) {
        return {} as SgGeometry<SgSimpleGeometry>;
    }

    static isInside(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        point: SgPoint | SgGeometryPoint
    ): boolean {
        return this.isAround(geometry, point, 0);
    }

    static isAround(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        point: SgPoint | SgGeometryPoint,
        delta: number = 0
    ): boolean {
        return false;
    }

    static scale(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        coefficient: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgSimpleGeometry {
        return this.getGeometry(geometry);
    }

    static reverseScale(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        coefficient: number,
        origin?: SgGeometryPoint | SgPoint
    ): SgSimpleGeometry {
        return this.scale(
            geometry,
            coefficient === 0 ? 1 : 1 / coefficient,
            origin
        );
    }

    static translate(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        vector: SgVector
    ): SgSimpleGeometry {
        return this.getGeometry(geometry);
    }

    static reverseTranslate(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        vector: SgVector
    ): SgSimpleGeometry {
        return this.translate(geometry, { x: -vector.x, y: -vector.y });
    }

    static move(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        to: SgGeometryPoint | SgPoint
    ): SgSimpleGeometry {
        return this.getGeometry(geometry);
    }

    static rotate(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        angle: number,
        origin: SgGeometryPoint | SgPoint = { x: 0, y: 0 }
    ): SgSimpleGeometry {
        return this.getGeometry(geometry);
    }

    static draw(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        ctx: CanvasRenderingContext2D,
        style?: Partial<SgGeometryCanvasStyle>
    ) {}

    static equals(
        geometry1: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>,
        geometry2: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>
    ): boolean {
        return false;
    }

    static getGeometry(
        geometry: SgSimpleGeometry | SgGeometry<SgSimpleGeometry>
    ): SgSimpleGeometry {
        return geometry instanceof SgGeometry
            ? geometry.getGeometry()
            : geometry;
    }
}

export default SgGeometry;
