import styled from "styled-components";

const Page = styled.div<{ $padding?: string, $overflow?: string }>`
display: flex;
flex-direction: column;
padding: ${(props) => props.$padding ?? props.theme.layout.padding};
overflow: ${(props) => props.$overflow};
gap: ${(props) => props.theme.layout.gap};
flex-grow: 1;
`
export default Page;