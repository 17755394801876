import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './Icon.types';

const Icon = ({ color, size, icon: IconComponent, id, className, pointer, onClick }: IconProps) => {
    const theme = useTheme()

    const sizePx = useMemo(() => {
        switch (size) {
            case 'xs':
                return '10px';
            case 'small':
                return '14px';
            case 'medium':
                return '18px';
            case 'large':
                return '24px';
            case 'xl':
                return '36px';
            default:
                return '18px';
        }
    }, [size]);

    return (
        <IconComponent
            id={id}
            className={className}
            style={{ fill: theme.colors[color ?? 'greyMedium'], width: sizePx, height: sizePx, cursor: pointer ? 'pointer' : undefined }}
            onClick={onClick}
        />
    );
}
export default Icon;