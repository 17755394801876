import { SgSchematicsOptions } from "../sg-schematics/SgSchematics.types";

const WIDTH = 41.4;
const HEIGHT = 59;

const BATH_HEIGHT = 95;
const BATH_START_X = 3000;
const BATH_START_Y = 2400;
const BATH_WAY_Y = 120;
const BATH_BETWEEN_Y = 35;
const BATHS = [
    [65, 60, 65, 60, 100, 95, 100, 59, 65],
    [135, 60, 135, 135, 60, 156],
    [127, 60, 60, 60, 60, 60, 65, 40, 65, 40, 95, 40, 40],
    [65, 60, 95, 60, 65, 60, 65, 60, 60, 60, 60],
    [40, 45, 40, 45, 40, 45, 40, 45, 40, 79, 40, 40, 40, 40, 40, 65, 40, 68],
    [65, 40, 40, 40, 65, 40, 65, 40, 65, 40, 65, 40, 55, 40, 40, 55, 40],
    [65, 40, 65, 40, 40, 65, 40, 65, 40, 65, 40, 45, 55, 40, 40, 65],
    [79, 40, 45, 65, 40, 65, 40, 65, 40, 40, 40, 40, 45, 40, 45, 40],
    [40, 65, 40, 65, 40, 65, 40, 163, 92, 40, 45, 40, 65],
    [71, 47, 65, 47, 50, 47, 47, 150, 47, 36, 144, 65],
    [65, 40, 40, 45, 40, 45, 45, 40, 40, 50, 47, 50, 50, 47, 50],
    [55, 40, 40, 40, 60, 62, 62, 40, 45, 40, 40, 65, 45, 55, 45, 40, 65, 110],
    [80, 65, 60, 45, 49, 45, 45, 173, 43, 60],
];

export const getBaths = () => {
    const baths: any[] = [];

    let y = BATH_START_Y;
    let x = BATH_START_X;
    BATHS.forEach((bathsRow, indexRow) => {
        bathsRow.forEach((width, indexBath) => {
            baths.push({
                shape: {
                    x,
                    y,
                    width: width,
                    height: BATH_HEIGHT,
                },
                _id: `Bain ${indexBath + 1} rangée ${indexRow + 1}`,
                data: {
                    status: Math.floor(Math.random() * 3),
                },
            });

            x += width;
        });

        x = BATH_START_X;
        y += BATH_HEIGHT + (indexRow % 2 === 0 ? BATH_BETWEEN_Y : BATH_WAY_Y);
    });

    return baths;
};

export const PLAN = {
    zones: [
        {
            _id: "main_zone",
            shape: { x: 0, y: 0, width: WIDTH * 100, height: HEIGHT * 100 },
        },
    ],
    walls: [
        { origin: { x: 0, y: 0 }, end: { x: WIDTH, y: 0 }, _id: "outer1" },
        {
            origin: { x: WIDTH, y: 0 },
            end: { x: WIDTH, y: HEIGHT },
            _id: "outer2",
        },
        {
            origin: { x: WIDTH, y: HEIGHT },
            end: { x: 0, y: HEIGHT },
            _id: "outer3",
        },
        { origin: { x: 0, y: HEIGHT }, end: { x: 0, y: 0 }, _id: "outer4" },

        { origin: { x: 20, y: 0 }, end: { x: 20, y: 18 }, _id: "middle_top" },
        {
            origin: { x: 20, y: 21 },
            end: { x: 20, y: 44 },
            _id: "middle_middle",
        },
        {
            origin: { x: 20, y: 47 },
            end: { x: 20, y: HEIGHT },
            _id: "middle_bottom",
        },

        { origin: { x: 0, y: 6 }, end: { x: 3, y: 6 }, _id: "stairs1_wall1" },
        { origin: { x: 3, y: 6 }, end: { x: 3, y: 0 }, _id: "stairs1_wall2" },

        {
            origin: { x: 3, y: 4 },
            end: { x: 20, y: 4 },
            _id: "compresseurs_reception_wall1",
        },
        {
            origin: { x: 9, y: 4 },
            end: { x: 9, y: 0 },
            _id: "compresseurs_reception_wall2",
        },

        {
            origin: { x: 14.5, y: 4 },
            end: { x: 14.5, y: 8.5 },
            _id: "bureau_reception_wall1",
        },
        {
            origin: { x: 14.5, y: 8.5 },
            end: { x: 20, y: 8.5 },
            _id: "bureau_reception_wall2",
        },

        {
            origin: { x: 20, y: 12 },
            end: { x: 13, y: 12 },
            _id: "degraissage_wall1",
        },
        {
            origin: { x: 13, y: 12 },
            end: { x: 13, y: 18 },
            _id: "degraissage_wall2",
        },
        {
            origin: { x: 13, y: 18 },
            end: { x: 20, y: 18 },
            _id: "degraissage_wall3",
        },

        {
            origin: { x: 20, y: 43 },
            end: { x: 17, y: 43 },
            _id: "decapage_wall1",
        },
        {
            origin: { x: 17, y: 20 },
            end: { x: 17, y: 43 },
            _id: "decapage_wall2",
        },
        {
            origin: { x: 17, y: 20 },
            end: { x: 23, y: 20 },
            _id: "decapage_wall3",
        },
        {
            origin: { x: 23, y: 20 },
            end: { x: 23, y: 23 },
            _id: "decapage_wall4",
        },
        {
            origin: { x: 23, y: 20 },
            end: { x: 23, y: 23 },
            _id: "decapage_wall5",
        },
        {
            origin: { x: 20, y: 39.5 },
            end: { x: 17, y: 39.5 },
            _id: "decapage_wall6",
        },

        {
            origin: { x: 8, y: HEIGHT },
            end: { x: 8, y: 54 },
            _id: "rack_wall1",
        },
        { origin: { x: 8, y: 54 }, end: { x: 17, y: 54 }, _id: "rack_wall2" },

        {
            origin: { x: 20, y: 48 },
            end: { x: 17, y: 48 },
            _id: "bureau_rack_wall1",
        },
        {
            origin: { x: 17, y: 48 },
            end: { x: 17, y: 56 },
            _id: "bureau_rack_wall2",
        },
        {
            origin: { x: 17, y: 56 },
            end: { x: 20, y: 56 },
            _id: "bureau_rack_wall3",
        },

        { origin: { x: 20, y: 47 }, end: { x: 32, y: 47 }, _id: "rack2_wall1" },
        {
            origin: { x: 32, y: 47 },
            end: { x: 32, y: HEIGHT },
            _id: "rack2_wall2",
        },

        {
            origin: { x: WIDTH, y: 47 },
            end: { x: 34, y: 47 },
            _id: "local_acide_wall1",
        },
        {
            origin: { x: 34, y: 47 },
            end: { x: 34, y: 52 },
            _id: "local_acide_wall2",
        },
        {
            origin: { x: 34, y: 52 },
            end: { x: WIDTH, y: 52 },
            _id: "local_acide_wall3",
        },

        { origin: { x: 20, y: 6 }, end: { x: 23, y: 6 }, _id: "stairs2_wall1" },
        { origin: { x: 23, y: 6 }, end: { x: 23, y: 0 }, _id: "stairs2_wall2" },

        { origin: { x: 20, y: 8 }, end: { x: 25, y: 8 }, _id: "bureaux_wall1" },
        {
            origin: { x: 25, y: 8 },
            end: { x: 27, y: 10 },
            _id: "bureaux_wall2",
        },
        {
            origin: { x: 27, y: 10 },
            end: { x: 27, y: 17 },
            _id: "bureaux_wall3",
        },
        {
            origin: { x: 27, y: 17 },
            end: { x: 20, y: 17 },
            _id: "bureaux_wall4",
        },
        {
            origin: { x: 20, y: 18 },
            end: { x: 23.5, y: 18 },
            _id: "bureaux_wall5",
        },
        {
            origin: { x: 23.5, y: 18 },
            end: { x: 23.5, y: 17 },
            _id: "bureaux_wall6",
        },

        {
            origin: { x: 37, y: 0 },
            end: { x: 37, y: 4.5 },
            _id: "poste_hta_wall1",
        },
        {
            origin: { x: 37, y: 4.5 },
            end: { x: WIDTH, y: 4.5 },
            _id: "poste_hta_wall2",
        },

        {
            origin: { x: WIDTH, y: 6.5 },
            end: { x: 37, y: 6.5 },
            _id: "bureau_condamne_wall1",
        },
        {
            origin: { x: 37, y: 6.5 },
            end: { x: 37, y: 12 },
            _id: "bureau_condamne_wall2",
        },
        {
            origin: { x: 37, y: 12 },
            end: { x: WIDTH, y: 12 },
            _id: "bureau_condamne_wall3",
        },

        {
            origin: { x: 35.5, y: 12 },
            end: { x: 31, y: 12 },
            _id: "bureau2_wall1",
        },
        {
            origin: { x: 31, y: 12 },
            end: { x: 31, y: 16 },
            _id: "bureau2_wall2",
        },

        {
            origin: { x: WIDTH, y: 13 },
            end: { x: 34, y: 13 },
            _id: "zone_bains_wall1",
        },
        {
            origin: { x: 34, y: 13 },
            end: { x: 34, y: 17 },
            _id: "zone_bains_wall2",
        },
        {
            origin: { x: 34, y: 17 },
            end: { x: 32, y: 17 },
            _id: "zone_bains_wall3",
        },
        {
            origin: { x: 32, y: 21 },
            end: { x: WIDTH, y: 21 },
            _id: "zone_bains_wall4",
        },
    ].map((w) => ({
        _id: w._id,
        shape: {
            origin: {
                x: w.origin.x * 100,
                y: w.origin.y * 100,
            },
            end: {
                x: w.end.x * 100,
                y: w.end.y * 100,
            },
        },
    })),
};

export const getOptions = (
    theme: any,
    isDarkMode: boolean
): SgSchematicsOptions => ({
    zoom: true,
    pan: true,
    resolution: 0.5,
    grid: true,
    crosshair: true,
    style: {
        grid: isDarkMode ? theme.colors.greyDarker : theme.colors.greyLight,
        background: theme.layout.background,
    },
});
