import { useState } from 'react';
import Toast from '../Toast/Toast';
import { ToastData } from '../Toast/Toast.types';
import styled from 'styled-components';
import { useNotificationContext } from '../../contexts';

const ToastQueueDiv = styled.div`
position: fixed;
right: 12px;
bottom: 12px;
display: flex;
flex-direction: column;
gap: 12px;
width: 400px;
z-index: 110;
`

const ToastQueue = () => {
    const { notifications, removeNotification } = useNotificationContext();
    const [toastForInfo, setToastForInfo] = useState<ToastData | null>(null);

    if (!notifications.length) return null;

    return (
        <ToastQueueDiv>
            {notifications.map(toast => (
                <Toast
                    key={toast._id}
                    toast={toast}
                    onTransitionExit={() => removeNotification(toast._id)}
                    onClick={() => setToastForInfo(toast)}
                />
            ))}
        </ToastQueueDiv>
    )
}

//{!!toastForInfo && <ToastInfoModal toast={toastForInfo} onClose={() => setToastForInfo(null)} />}
export default ToastQueue;