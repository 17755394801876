import { SgLine, SgPoint, SgSegment } from "./SgGeometry.interfaces";
import SgGeometryPoint from "./SgGeometryPoint.class";
import SgGeometrySegment from "./SgGeometrySegment.class";

class SgGeometryLine {
    private equation!: SgLine;

    constructor(line: SgLine | SgSegment) {
        this.setEquation(line);
    }

    public setEquation(line: SgLine | SgSegment) {
        this.equation = (line as any).hasOwnProperty("origin")
            ? SgGeometryLine.equation(line as SgSegment)
            : (line as SgLine);
    }

    public getEquation(): SgLine {
        return this.equation;
    }

    public distance(point: SgPoint | SgGeometryPoint): number {
        return SgGeometryLine.distance(this, point);
    }

    public isVertical(): boolean {
        return this.equation.b === 0;
    }

    public isHorizontal(): boolean {
        return this.equation.a === 0;
    }

    public isColinear(line: SgLine | SgGeometryLine): boolean {
        return SgGeometryLine.colinear(this, line);
    }

    public isParallel(line: SgLine | SgGeometryLine): boolean {
        return SgGeometryLine.parallel(this, line);
    }

    public getIntersection(line: SgLine | SgGeometryLine): SgPoint | undefined {
        return SgGeometryLine.intersect(this, line);
    }

    public getPerpendicular(point: SgPoint | SgGeometryPoint): SgLine {
        return SgGeometryLine.perpendicular(this, point);
    }

    static distance(
        line: SgLine | SgGeometryLine,
        point: SgPoint | SgGeometryPoint
    ): number {
        const equation = line instanceof this ? line.getEquation() : line;
        const p = SgGeometryPoint.getGeometry(point);
        return (
            Math.abs(equation.a * p.x + equation.b * p.y + equation.c) /
            equation.distanceDivider
        );
    }

    static equation(segment: SgGeometrySegment | SgSegment): SgLine {
        const s = SgGeometrySegment.getGeometry(segment);
        const a = s.origin.y - s.end.y;
        const b = s.end.x - s.origin.x;
        const c = s.origin.x * s.end.y - s.end.x * s.origin.y;
        const slope = b !== 0 ? -a / b : undefined;

        return {
            a,
            b,
            c,
            slope,
            distanceDivider: Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)),
        };
    }

    static forX(
        line: SgLine | SgGeometryLine,
        point: SgPoint | SgGeometryPoint
    ): SgPoint | undefined {
        const equation = line instanceof this ? line.getEquation() : line;
        const p = SgGeometryPoint.getGeometry(point);

        if (equation.b === 0) return undefined;

        return {
            x: p.x,
            y: -(equation.a * p.x + equation.c) / equation.b,
        };
    }

    static forY(
        line: SgLine | SgGeometryLine,
        point: SgPoint | SgGeometryPoint
    ): SgPoint | undefined {
        const equation = line instanceof this ? line.getEquation() : line;
        const p = SgGeometryPoint.getGeometry(point);

        if (equation.a === 0) return undefined;

        return {
            x: -(equation.b * p.y + equation.c) / equation.a,
            y: p.y,
        };
    }

    static perpendicular(
        line: SgLine | SgGeometryLine,
        point: SgPoint | SgGeometryPoint
    ): SgLine {
        const equation = line instanceof this ? line.getEquation() : line;
        const origin = SgGeometryPoint.getGeometry(point);
        if (equation.a === 0) {
            // Horizontal
            return this.equation({
                origin,
                end: { x: origin.x, y: origin.y + 100 },
            });
        }
        if (equation.b === 0) {
            // Verical
            return this.equation({
                origin,
                end: { x: origin.x + 100, y: origin.y },
            });
        }

        const slope = -equation.b / equation.a;

        const a = slope;
        const b = -1;
        const c = origin.y - slope * origin.x;
        return {
            a,
            b,
            c,
            distanceDivider: Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)),
        };
    }

    static compute(equation: SgLine, point: SgPoint | SgGeometryPoint): number {
        const p = SgGeometryPoint.getGeometry(point);
        return equation.a * p.x + equation.b * p.y + equation.c;
    }

    static colinear(
        line1: SgLine | SgGeometryLine,
        line2: SgLine | SgGeometryLine
    ): boolean {
        const equation1 = line1 instanceof this ? line1.getEquation() : line1;
        const equation2 = line2 instanceof this ? line2.getEquation() : line2;

        if (equation1.slope === equation2.slope) {
            return (
                SgGeometryLine.distance(equation1, { x: 0, y: 0 }) -
                    SgGeometryLine.distance(equation2, { x: 0, y: 0 }) <
                0.01
            );
        }

        return false;
    }

    static parallel(
        line1: SgLine | SgGeometryLine,
        line2: SgLine | SgGeometryLine
    ): boolean {
        const equation1 = line1 instanceof this ? line1.getEquation() : line1;
        const equation2 = line2 instanceof this ? line2.getEquation() : line2;

        return equation1.slope === equation2.slope;
    }

    static intersect(
        line1: SgLine | SgGeometryLine,
        line2: SgLine | SgGeometryLine
    ): SgPoint | undefined {
        const equation1 = line1 instanceof this ? line1.getEquation() : line1;
        const equation2 = line2 instanceof this ? line2.getEquation() : line2;
        try {
            return equation1.slope !== equation2.slope
                ? {
                      x:
                          (equation1.b * equation2.c -
                              equation2.b * equation1.c) /
                          (equation1.a * equation2.b -
                              equation2.a * equation1.b),
                      y:
                          (equation1.c * equation2.a -
                              equation2.c * equation1.a) /
                          (equation1.a * equation2.b -
                              equation2.a * equation1.b),
                  }
                : undefined;
        } catch {
            return undefined;
        }
    }
}

export default SgGeometryLine;
