import { useMemo } from "react";
import styled from "styled-components";
import { Color } from "../../types/styling.types";
import { PillProps } from "./Pill.types";

const StyledDiv = styled.div<{ $size: number, $color: Color }>`
width: ${(props) => props.$size}px;
height: ${(props) => props.$size}px;
border-radius: 50%;
background-color: ${(props) => props.$color ? props.theme.colors[props.$color] : ' transparent'};
`

const Pill = ({ color, size, children }: PillProps) => {

    const sizePx = useMemo(() => {
        switch (size) {
            case 'xs':
                return 10;
            case 'small':
                return 14;
            case 'medium':
                return 18;
            case 'large':
                return 24;
            case 'xl':
                return 36;
            default:
                return 18;
        }
    }, [size]);

    return (
        <StyledDiv $size={sizePx} $color={color}>{children}</StyledDiv>
    )
}
export default Pill;