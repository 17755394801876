import i18next from 'i18next';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';
import enGlobal from './sg-ui/translations/en.json';
import frGlobal from './sg-ui/translations/fr.json';
import en from './translations/en.json';
import fr from './translations/fr.json';

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: ['en'],
        resources: {
            en: { ...en, ...enGlobal },
            fr: { ...fr, ...frGlobal },
        },
        interpolation: {
            // react already saves from xss
            escapeValue: false
        }
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
