import { Translation } from "../types/translation.types";

export const translateEnum = <T extends string>(
    e: Record<string, T>,
    i18n: string,
    t: (s: string) => string
): { key: T; label: string }[] =>
    Object.keys(e).map((en) => ({
        key: e[en],
        label: t(`${i18n}:${e[en].toLowerCase()}`),
    }));

export const translateText = (
    text: string | Translation,
    t: (s: string, v?: Record<string, string | undefined>) => string
): string =>
    typeof text === "string"
        ? text
        : t(text.key, { ns: text.namespace, ...text.variables });
