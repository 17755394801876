import styled from "styled-components";
import { ProgressBarProps } from "./ProgressBar.types";

const StyledDiv = styled.div<{ $progess: number }>`
display: flex;
align-items: center;
justify-content: center;
padding-right: 8px;
position: relative;
border-radius: 4px;
height: 20px;
border: ${(props) => props.theme.colors.greyLight} 1px solid;
&::after {
    content: "";
    height: 100%;
    width: ${(props) => props.$progess}%;
    background-color: ${(props) => props.theme.colors.success};
    position: absolute;
    top: 0;
    left: 0;
}
& > span {
    position: relative;
    z-index: 1;
}
`

const ProgressBar = ({ progress }: ProgressBarProps) => {

    return (
        <StyledDiv $progess={progress}>
            <div />
            <span>{progress}%</span>
        </StyledDiv>
    )
}
export default ProgressBar;