import { createContext, ReactElement, useCallback, useContext, useState } from "react";
import { v4 } from 'uuid';
import { ToastQueue } from "../../components";
import { ToastData } from "../../components/Toast/Toast.types";
import { NotificationProviderReturnInterface } from "./NotificationProvider.types";

export const NotificationContext = createContext<NotificationProviderReturnInterface>({
    notifications: [],
    notify: () => null,
    removeNotification: () => null,
});

const NotificationProvider = ({ children }: { children: ReactElement }) => {
    const [notifications, setNotifications] = useState<ToastData[]>([]);

    const notify = useCallback((toast: Omit<ToastData, '_id'>) => setNotifications(notifications => [...notifications, { ...toast, _id: v4() }]), []);
    const removeNotification = useCallback((_id: string) => setNotifications(notifications => notifications.filter(t => t._id !== _id)), []);

    return (
        <NotificationContext.Provider value={{ notifications, notify, removeNotification }}>
            <ToastQueue />
            {children}
        </NotificationContext.Provider>
    );
};

const useNotificationContext = () => {
    return useContext(NotificationContext);
};

export { NotificationProvider, useNotificationContext };